import { logger } from "@/lib/logger";
import { Observable, Operation, RequestHandler } from "@apollo/client";
import AdminDashboardPageQuery from "./dashboard.json";
import DocumentsPageQuery from "./documents.json";
import MeQuery from "./me.json";
import PlantsFilterQuery from "./plants-filter.json";
import PlantsPageQuery from "./plants.json";
import UsersPageQuery from "./users.json";

const responses = {
  AdminDashboardPageQuery,
  PlantsFilterQuery,
  PlantsPageQuery,
  UsersPageQuery,
  MeQuery: {
    data: {
      me: MeQuery
    }
  },
  ActivateUser: {
    data: {
      activateUser: MeQuery
    }
  },
  DocumentsPageQuery
};

export const mockRequest: RequestHandler = (operation: Operation) => {
  return new Observable((observer) => {
    let response = responses[operation.operationName as keyof typeof responses];
    let timeout = 500;

    switch (operation.operationName) {
      case "Me": {
        timeout = 50;
        response = {
          data: {
            me: MeQuery
          }
        };
        break;
      }

      case "UsersPage": {
        response = UsersPageQuery;
        break;
      }

      case "ActivateUser": {
        response = responses.ActivateUser;
        break;
      }

      case "AdminDashboardPage": {
        response = AdminDashboardPageQuery;
        break;
      }

      case "DocumentsPage": {
        const { take, skip, where, orderBy } = operation.variables;

        // TODO: not implemented filter by date in mocks

        // TODO: orderBy mocked only for name
        const tmp = [...DocumentsPageQuery.data.documents.items];
        const orderedItems = orderBy?.name
          ? tmp.sort((a, b) => {
              if (orderBy.name === "ASC") {
                return a.name.localeCompare(b.name);
              } else {
                return b.name.localeCompare(a.name);
              }
            })
          : DocumentsPageQuery.data.documents.items;

        const filteredItemsByName = where.name
          ? orderedItems.filter((item) =>
              item.name.toLowerCase().includes(where.name.toLowerCase())
            )
          : orderedItems;

        const filteredItemsByType = filteredItemsByName.filter((item) =>
          where.type ? item.type === where.type : true
        );

        const filteredItemsByPlant = filteredItemsByType.filter((item) =>
          where.plantId ? item.plant.id === parseInt(where.plantId, 10) : true
        );

        response = {
          ...DocumentsPageQuery,
          data: {
            ...DocumentsPageQuery.data,
            documents: {
              ...DocumentsPageQuery.data.documents,
              items: filteredItemsByPlant.slice(skip, skip + take),
              totalCount: filteredItemsByPlant.length
            }
          }
        };
        break;
      }

      default:
        if (!response) {
          const error = "Mock not available for following operation";
          logger.error(error, operation);
        }
        break;
    }

    setTimeout(() => {
      observer.next(response);
      observer.complete();
    }, timeout);
  });
};
