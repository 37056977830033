import { LoadingSpinner } from "../loading-spinner";
import { TableCell, TableRow } from "./table";

type TableLoaderProps = {
  colSpan: number;
};

export const TableLoader: React.FC<TableLoaderProps> = ({ colSpan }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <div className="flex justify-center w-full h-52">
          <LoadingSpinner />
        </div>
      </TableCell>
    </TableRow>
  );
};
