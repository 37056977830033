import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { graphql } from "@/lib/data/graphql";
import { FC, useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "@/lib/i18n";

export const PlantsFilterQuery = graphql(`
  query PlantsFilterQuery {
    plants {
      id
      name
    }
  }
`);

type Item = {
  id: number;
  name: string;
};

type PlantsFilterProps = {
  defaultValueId?: string;
  onChange: (plantId: string) => void;
  className?: string;
  selectFirstEntry?: boolean;
};

export const PlantsFilter: FC<PlantsFilterProps> = ({
  defaultValueId,
  onChange,
  className,
  selectFirstEntry
}) => {
  const { t } = useTranslation();

  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState<Item | null>(null);

  const { data, loading } = useQuery(PlantsFilterQuery);
  const items = useMemo(() => data?.plants ?? [], [data]);

  const filteredItems =
    query === ""
      ? items
      : items.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  const handleChange = (item: Item) => {
    setSelected(item);
    onChange(item?.id?.toString() || "");
  };

  useEffect(() => {
    if (defaultValueId) {
      const item = items.find((item) => item.id === parseInt(defaultValueId));
      if (item) {
        setSelected(item);
      } else {
        setSelected(null);
      }
    } else if (selectFirstEntry && items?.[0]) {
      setSelected(items[0]);
      onChange(items[0]?.id.toString());
    }
  }, [data, defaultValueId, items, onChange, selectFirstEntry]);

  if (loading) {
    return (
      <div
        className={clsx([
          className,
          "w-[150px] h-[32px] dark:bg-white/5 animate-pulse rounded-lg"
        ])}
      ></div>
    );
  }

  // TODO: extract combobox to separate component
  return (
    <div
      className={clsx([
        className,
        // Basic layout
        "group relative block w-full",
        // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
        "before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow",
        // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
        "dark:before:hidden",
        // Disabled state
        "has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none"
      ])}
    >
      <Combobox
        value={selected}
        onChange={handleChange}
        onClose={() => setQuery("")}
      >
        <div
          className={clsx(
            "relative", // Focus ring
            "after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset",
            " after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-gray-400",
            // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
            "before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)]  before:shadow",
            // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
            "dark:before:hidden"
          )}
        >
          <ComboboxInput
            className={clsx(
              // Typography
              "text-base/6 text-zinc-950 placeholder:text-zinc-500 sm:text-sm/6 dark:text-white",
              "pl-[calc(theme(spacing[3.5])-1px)] py-[calc(theme(spacing[2.5])-1px)]",

              // focus
              "sm:pl-[calc(theme(spacing[3])-1px)] sm:py-[calc(theme(spacing[1.5])-1px)]",

              "w-full rounded-lg border border-zinc-950/10 py-1.5 pr-8 pl-3",
              "bg-white text-gray-900 focus:outline-none ",

              "dark:bg-white/5 dark:text-white dark:border-white/10 dark:focus:ring-white/25",
              "truncate overflow-ellipsis whitespace-nowrap"
            )}
            displayValue={(item: Item) => item?.name}
            placeholder={t("plants.filters.allPlants")}
            onChange={(event) => setQuery(event.target.value)}
          />

          <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
            <ChevronDownIcon
              className={clsx(
                "size-4 fill-gray-500 group-hover:fill-gray-700",
                "dark:fill-white/60 dark:group-hover:fill-white"
              )}
            />
          </ComboboxButton>
        </div>

        <ComboboxOptions
          anchor="bottom"
          transition
          className={clsx(
            "w-[var(--input-width)] rounded-xl border p-1 [--anchor-gap:var(--spacing-1)] empty:invisible",
            "bg-white border-gray-200",
            "dark:bg-gray-800 dark:border-gray-700",
            "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
          )}
        >
          <ComboboxOption
            value="all"
            className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-700"
          >
            <CheckIcon className="invisible size-4 fill-gray-500 dark:fill-white group-data-[selected]:visible" />
            <div className="text-sm text-gray-900 dark:text-white opacity-70">
              {t("plants.filters.allPlants")}
            </div>
          </ComboboxOption>
          {filteredItems.map((item) => (
            <ComboboxOption
              key={item.id}
              value={item}
              className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-gray-100 dark:data-[focus]:bg-gray-700"
            >
              <CheckIcon className="invisible size-4 fill-gray-500 dark:fill-white group-data-[selected]:visible" />
              <div className="text-sm text-gray-900 dark:text-white">
                {item.name}
              </div>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </div>
  );
};
