import { TableCell, TableRow } from "@/ui/table/table";
import { Checkbox } from "@/ui/form/checkbox";

type Props = {
  subject: string;
  actions: string[];
  subjectActionMap: Record<string, Set<string>>;
  togglePermission: (subject: string, action: string) => void;
  disabled?: boolean;
};

export const RolePermissionItem: React.FC<Props> = ({
  subject,
  actions,
  subjectActionMap,
  togglePermission,
  disabled
}) => {
  return (
    <TableRow
      key={subject}
      className="border-b border-zinc-200 dark:border-zinc-700 hover:bg-zinc-100 dark:hover:bg-zinc-800 transition-colors"
    >
      <TableCell className="font-bold">{subject}</TableCell>
      {actions.map((action) => (
        <TableCell key={`${subject}-${action}`}>
          <Checkbox
            aria-label={`${subject}-${action}`}
            checked={subjectActionMap[subject]?.has(action) || false}
            onChange={() => togglePermission(subject, action)}
            className="cursor-pointer"
            disabled={disabled}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};
