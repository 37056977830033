/* eslint-disable no-restricted-imports */
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@/ui/Theme";
import { Id, toast, ToastContainer, ToastOptions } from "react-toastify";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import { Toast, ToastProps } from "@/ui/toast/Toast";
import clsx from "clsx";

export const Toaster: React.FC = () => {
  const { theme } = useTheme();

  return (
    <ToastContainer
      position="top-right"
      autoClose={6666}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      aria-live="assertive"
      theme={theme}
      className="p-4"
      icon={false}
      toastClassName={clsx(
        "relative flex min-w-[300px] p-3 mb-1 rounded-md shadow-lg",
        "border border-zinc-400 dark:border-zinc-700",
        "bg-white dark:bg-[#18181B]",
        "text-black dark:text-white text-sm"
      )}
    />
  );
};

export const toaster = (
  toastProps: ToastProps,
  toastOptions: ToastOptions
): Id => toast(<Toast {...toastProps} />, toastOptions);

toaster.success = (toastProps: ToastProps, toastOptions?: ToastOptions): Id =>
  toast.success(
    <Toast
      {...toastProps}
      icon={<CheckCircleIcon className="w-[26px] h-[26px] mr-4" />}
    />,
    {
      ...toastOptions
    }
  );

toaster.info = (toastProps: ToastProps, toastOptions?: ToastOptions): Id =>
  toast.info(
    <Toast
      {...toastProps}
      icon={<InformationCircleIcon className="w-[26px] h-[26px] mr-4" />}
    />,
    {
      ...toastOptions
    }
  );

toaster.warning = (toastProps: ToastProps, toastOptions?: ToastOptions): Id =>
  toast.warning(
    <Toast
      {...toastProps}
      icon={<ExclamationTriangleIcon className="w-[26px] h-[26px] mr-4" />}
    />,
    {
      ...toastOptions
    }
  );

toaster.error = (toastProps: ToastProps, toastOptions?: ToastOptions): Id =>
  toast.error(
    <Toast
      {...toastProps}
      icon={<ExclamationCircleIcon className="w-[26px] h-[26px] mr-4" />}
    />,
    {
      ...toastOptions
    }
  );
