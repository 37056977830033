import { useSession } from "@/lib/auth";
import { MultiFactorAuthProvider } from "@/lib/auth/multi-factor-authentication/MultiFactorAuthenticationProvider";
import { RecaptchaProvider } from "@/lib/auth/multi-factor-authentication/RecaptchaProvider";
import { useRef } from "react";
import { VerifySecondFactorFlow } from "./VerifySecondFactorFlow";
import { Logo } from "@/ui/Logo";

export const VerifySecondFactorPage: React.FC = () => {
  const { auth } = useSession();
  const recaptchaContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 dark:bg-gray-900 p-4">
      <div className="w-full max-w-md bg-white dark:bg-gray-800 rounded-lg p-8 border border-gray-200 dark:border-gray-700">
        <div className="flex justify-center mb-6">
          <Logo className="px-2" />
        </div>

        <div id="recaptcha-container" ref={recaptchaContainerRef}></div>

        <RecaptchaProvider
          auth={auth}
          containerId="recaptcha-container"
          elementRef={recaptchaContainerRef.current}
        >
          <MultiFactorAuthProvider auth={auth}>
            <VerifySecondFactorFlow />
          </MultiFactorAuthProvider>
        </RecaptchaProvider>
      </div>
    </div>
  );
};
