import { AddPermissionDialogBtn } from "@/app/permissions/AddPermissionDialogBtn";
import { AddRoleDialogBtn } from "@/app/permissions/AddRoleDialogBtn";
import { RoleFilter } from "@/app/permissions/RoleFilter";
import {
  PermissionItemFragment,
  PermissionRoleItemFragment,
  RolePermissionsTable,
  useSubjectActionMap
} from "@/app/permissions/RolePermissionsTable";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/divider";
import { GraphqlErrorAlert } from "@/ui/error/GraphqlErrorAlert";
import { ListingPageWrapper } from "@/ui/ListingPageWrapper";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { TableFilterWrapper } from "@/ui/table/TableFilterWrapper";
import { Heading } from "@/ui/typo/heading";
import { Text } from "@/ui/typo/text";
import { useQuery } from "@apollo/client";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSession } from "@/lib/auth";

export const PermissionsPageQuery = graphql(
  `
    query Permissions {
      adminRoles {
        ...PermissionRoleItem
      }
      adminResources {
        ...PermissionItem
      }
    }
  `,
  [PermissionRoleItemFragment, PermissionItemFragment]
);

export const RolePermissionsPage: FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const ability = useSession().ability;

  const { data, loading, error } = useQuery(PermissionsPageQuery);
  const [selectedRoleId, setSelectedRoleId] = useState<string | undefined>(
    searchParams.get("role") ?? undefined
  );
  const { subjectActionMap } = useSubjectActionMap(
    data?.adminRoles,
    selectedRoleId
  );

  if (error) {
    return <GraphqlErrorAlert error={error} />;
  }

  return (
    <ListingPageWrapper>
      <Heading>{t("permissions.listing.title")}</Heading>

      <Divider />

      <TableFilterWrapper>
        <RoleFilter
          selectedRoleId={selectedRoleId}
          onChange={setSelectedRoleId}
        />
        {ability?.can("create", "roles") ? (
          <AddRoleDialogBtn setSelectedRoleId={setSelectedRoleId} />
        ) : null}
        {ability?.can("create", "resources") ? (
          <AddPermissionDialogBtn
            subjectActionMap={subjectActionMap}
            selectedRoleId={selectedRoleId}
          />
        ) : null}
      </TableFilterWrapper>

      {data?.adminRoles && selectedRoleId ? (
        <RolePermissionsTable
          roles={data?.adminRoles}
          allPermissions={data?.adminResources}
          selectedRoleId={selectedRoleId}
        />
      ) : loading ? (
        <div className="flex mt-14 items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <Text>{t("permissions.listing.noData")}</Text>
      )}
      {/*<ResourcesManagement isOpen={true} onClose={() => null} />*/}
    </ListingPageWrapper>
  );
};
