import { UserCredential } from "firebase/auth";
import { FC } from "react";
import { DemoLoginButton } from "./DemoLoginButton";
import { GoogleLoginButton } from "./GoogleLoginButton";

export type ThirdPartyLoginButtonsProps = {
  disabled?: boolean;
  onError: (error: unknown) => void;
  onSuccess: (result: UserCredential) => void;
};

export const ThirdPartyLoginButtons: FC<ThirdPartyLoginButtonsProps> = ({
  disabled,
  onError,
  onSuccess
}) => {
  return (
    <div className="social-login-buttons">
      <div className="mt-4">
        <GoogleLoginButton
          disabled={disabled}
          onSuccess={onSuccess}
          onError={onError}
        />
      </div>
      <div className="mt-4">
        <DemoLoginButton
          disabled={disabled}
          onSuccess={onSuccess}
          onError={onError}
        />
      </div>
    </div>
  );
};
