import { UserItemFragment } from "@/app/user-management/UserItem";
import { UsersPageQuery } from "@/app/user-management/UsersPage";
import { graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { logger } from "@/lib/logger";
import { Button } from "@/ui/button/button";
import { Dialog, DialogActions, DialogDescription } from "@/ui/dialog";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { toaster } from "@/ui/toast/Toaster";
import { useMutation } from "@apollo/client";
import clsx from "clsx";
import { FC } from "react";

export const DeleteUserMutation = graphql(`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`);

type Props = {
  userId: string | null;
  userEmail: string | null;
  isOpen: boolean;
  onClose: () => void;
};

export const DeleteUserDialog: FC<Props> = ({
  userId,
  userEmail,
  onClose,
  isOpen
}) => {
  const { t } = useTranslation();

  const [deleteUser, { loading }] = useMutation(DeleteUserMutation, {
    update: (cache, { data }) => {
      if (data?.deleteUser && userId) {
        const existingUsers = cache.readQuery({
          query: UsersPageQuery
        });

        if (existingUsers?.users) {
          // update the cache to exclude the deleted user
          cache.writeQuery({
            query: UsersPageQuery,
            data: {
              // TODO: Fix this uid vs id
              users: existingUsers.users.filter(
                (user) => readFragment(UserItemFragment, user).uid !== userId
              )
            }
          });
        }
      }
    }
  });

  const handleDeleteUser = async () => {
    if (!userId) {
      return;
    }

    deleteUser({
      variables: { userId },
      onCompleted: () => {
        onClose();
      },
      onError: (error) => {
        toaster.error({
          description: t("userManagement.deleteUser.error.description")
        });
        logger.error(error);
      }
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      title={t("userManagement.deleteUser.title")}
    >
      <DialogDescription>
        {t("userManagement.deleteUser.description", { email: userEmail })}
      </DialogDescription>
      <DialogActions>
        <Button type="button" outline onClick={onClose}>
          {t("userManagement.deleteUser.actions.cancel.label")}
        </Button>
        <Button
          disabled={loading}
          className={clsx({ "pointer-events-none opacity-50": loading })}
          onClick={handleDeleteUser}
        >
          {loading ? (
            <div className="pr-2">
              <LoadingSpinner size="sm" />
            </div>
          ) : null}
          {t("userManagement.deleteUser.actions.delete.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
