import * as Sentry from "@sentry/capacitor";

export const logger = {
  error: (message?: unknown, ...optionalParams: unknown[]) => {
    Sentry.captureException(message);
    console.error(message, ...optionalParams);
  },
  info: (message?: unknown, ...optionalParams: unknown[]) => {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams);
  }
};
