import { logger } from "@/lib/logger";
import { AuthError } from "firebase/auth";

export type FirebaseErrorKey =
  | "userNotFound"
  | "wrongPassword"
  | "invalidEmail"
  | "userDisabled"
  | "emailAlreadyInUse"
  | "weakPassword"
  | "operationNotAllowed"
  | "accountExistsWithDifferentCredential"
  | "invalidCredential"
  | "invalidVerificationCode"
  | "invalidVerificationId"
  | "captchaCheckFailed"
  | "unknown"
  | "unverifiedEmail"
  | "unauthorizedEmail"
  | "internalError"
  | "popupClosedByUser"
  | "secondFactorAlreadyInUse"
  | "popupBlocked"
  | "requiresRecentLogin";

export function mapFirebaseErrorKey(error: AuthError): FirebaseErrorKey {
  if (!error || !error.code) {
    // Not Firebase Error, rethrow
    throw error;
  }

  switch (error.code) {
    case "auth/user-not-found":
      return "userNotFound";
    case "auth/wrong-password":
      return "wrongPassword";
    case "auth/invalid-email":
      return "invalidEmail";
    case "auth/user-disabled":
      return "userDisabled";
    case "auth/email-already-in-use":
      return "emailAlreadyInUse";
    case "auth/weak-password":
      return "weakPassword";
    case "auth/operation-not-allowed":
      return "operationNotAllowed";
    case "auth/account-exists-with-different-credential":
      return "accountExistsWithDifferentCredential";
    case "auth/invalid-credential":
      return "invalidCredential";
    case "auth/invalid-verification-code":
      return "invalidVerificationCode";
    case "auth/invalid-verification-id":
      return "invalidVerificationId";
    case "auth/captcha-check-failed":
      return "captchaCheckFailed";
    case "auth/unverified-email":
      return "unverifiedEmail";
    case "auth/internal-error":
      // Special check for error coming from blocking function
      if (error.message.includes("Unauthorized email")) {
        return "unauthorizedEmail";
      }
      return "internalError";
    case "auth/popup-closed-by-user":
      return "popupClosedByUser";
    case "auth/second-factor-already-in-use":
      return "secondFactorAlreadyInUse";
    case "auth/popup-blocked":
      return "popupBlocked";
    case "auth/requires-recent-login":
      return "requiresRecentLogin";
    default:
      logger.error("Unknown Firebase error", error);
      return "unknown";
  }
}
