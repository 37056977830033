import { useContext } from "react";
import { MultiFactorAuthContext } from "./MultiFactorAuthenticationProvider";

export const useMultiFactorAuthentication = () => {
  const context = useContext(MultiFactorAuthContext);
  if (!context) {
    throw new Error(
      "useMultiFactorAuthentication must be used within a MultiFactorAuthContext"
    );
  }
  return context;
};
