import { FragmentOf, graphql } from "@/lib/data/graphql";

export const MeFragment = graphql(
  `
    fragment MeFragment on User @_unmask {
      id
      uid
      email
      displayName
      phoneNumber
      photoUrl
      emailVerified
      roles {
        id
        name
        resources {
          id
          name
        }
      }
    }
  `,
  []
);

export type MeFragmentType = FragmentOf<typeof MeFragment>;
