import { defineAbility } from "@casl/ability";
import { MeFragmentType } from "./Me.fragment";

export function defineAbilitiesForMe(me: MeFragmentType) {
  return defineAbility((can) => {
    const abilities = me.roles
      .map((role) => role.resources.map((resource) => resource.name))
      .flat();
    abilities.forEach((ability) => {
      const tuple = ability.split(":");
      can(tuple[1], tuple[0]);
    });
  });
}
