import { ApolloError } from "@apollo/client";
import React from "react";
import { useTranslation } from "@/lib/i18n";
import { ErrorBox } from "@/ui/error/ErrorBox";

// TODO: sync with BE if there will be custom errors
const SERVER_ERROR_CODES = [
  "InternalServerError",
  "UnknownException",
  "Forbidden"
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type GraphQLCustomErrorExtensions = Record<string, any>;

type GraphqlErrorAlertProps = {
  error?: ApolloError;
  messageRenderer?: (
    code: string,
    context?: Record<string, never>
  ) => React.ReactNode;
};

export const GraphqlErrorAlert: React.FC<GraphqlErrorAlertProps> = ({
  error,
  messageRenderer
}) => {
  const { t } = useTranslation();

  if (!error) {
    return null;
  }

  const code = parseErrorCode(error);
  const extensions = parseExtensions(error);

  if (code && messageRenderer) {
    return (
      <ErrorBox
        title={t("error.graphql.generic.title")}
        description={t("error.graphql.generic.description")}
      >
        {messageRenderer(code, extensions?.context as never)}
      </ErrorBox>
    );
  }

  if (code && SERVER_ERROR_CODES.includes(code)) {
    return (
      <ErrorBox
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        title={t(`error.graphql.${code}.title`)}
      />
    );
  }

  return (
    <ErrorBox
      title={t("error.graphql.generic.title")}
      description={t("error.graphql.generic.description")}
    />
  );
};

function parseErrorCode(error: ApolloError): string | undefined {
  return (error.graphQLErrors[0]?.extensions?.code as string) || undefined;
}

function parseExtensions(
  error: ApolloError
): GraphQLCustomErrorExtensions | undefined {
  return error.graphQLErrors[0]?.extensions || undefined;
}
