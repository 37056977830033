import { AuthError, useSignInWithEmailAndPassword } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { FormComponentProps } from "@/ui/form/form";
import {
  EnvelopeIcon,
  EyeIcon,
  EyeSlashIcon,
  KeyIcon
} from "@heroicons/react/24/outline";
import { UserCredential } from "firebase/auth";
import { useAtom } from "jotai";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { disabledAtom } from "./atoms/loginAtoms";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Button } from "@/ui/button/button";

export type EmailAndPasswordFormProps = FormComponentProps<
  EmailAndPasswordFormValues,
  UserCredential,
  AuthError
>;

export type EmailAndPasswordFormValues = {
  email: string;
  password: string;
};

export const EmailAndPasswordForm = (props: EmailAndPasswordFormProps) => {
  const { onError, onSuccess, onSubmit, disabled } = props;
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [singIn, , loading] = useSignInWithEmailAndPassword();
  const [, setDisabled] = useAtom(disabledAtom);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<EmailAndPasswordFormValues>();

  const handle = async (data: EmailAndPasswordFormValues) => {
    onSubmit?.(data);
    setDisabled(true);
    try {
      const result = await singIn(data.email, data.password);
      onSuccess?.(result);
    } catch (error) {
      onError?.(error as AuthError);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(handle)}>
        <div className="mt-2">
          <div>
            <div className="form-control">
              <label className="label" htmlFor="email">
                <span className="label-text text-black dark:text-gray-300">
                  {t("login.email")}
                </span>
              </label>
              <div className="form-control flex flex-row items-center rounded-md border border-gray-300 dark:border-gray-600 px-3 bg-transparent dark:bg-transparent">
                <EnvelopeIcon
                  className="text-gray-500 dark:text-gray-400 h-[1em] w-[1em]"
                  fontSize="18"
                />
                <input
                  id="email"
                  placeholder={t("login.email")}
                  className="input w-full bg-transparent focus:border-transparent focus:outline-0 transition-all input-sm text-black dark:text-white"
                  type="email"
                  inputMode="email"
                  disabled={loading || disabled}
                  aria-invalid={errors.email ? "true" : "false"}
                  {...register("email", {
                    required: t("errors.validation.required", { ns: "common" })
                  })}
                />
              </div>
            </div>
            {errors.email && (
              <span className="text-xs text-red-500">
                {errors.email.message}
              </span>
            )}
            <div className="form-control mt-3">
              <label className="label" htmlFor="password">
                <span className="label-text text-black dark:text-gray-300">
                  {t("login.password")}
                </span>
              </label>
              <div className="form-control flex flex-row items-center rounded-md border border-gray-300 dark:border-gray-600 px-3 bg-transparent dark:bg-transparent">
                <KeyIcon
                  className="text-gray-500 dark:text-gray-400 h-[1em] w-[1em]"
                  fontSize="18"
                />
                <input
                  id="password"
                  placeholder={t("login.password")}
                  className="input w-full bg-transparent focus:border-transparent focus:outline-0 transition-all input-sm text-black dark:text-white"
                  type={showPassword ? "text" : "password"}
                  disabled={loading || disabled}
                  aria-invalid={errors.password ? "true" : "false"}
                  {...register("password", {
                    required: t("errors.validation.required", { ns: "common" })
                  })}
                />
                <button
                  disabled={loading || disabled}
                  onClick={() => setShowPassword(!showPassword)}
                  type="button"
                  aria-label="Show/Hide password"
                  className="btn hover:bg-gray-200 dark:hover:bg-gray-600 btn-xs btn-circle btn-ghost"
                >
                  {showPassword ? (
                    <EyeSlashIcon
                      className="text-gray-500 dark:text-gray-400 h-[1em] w-[1em]"
                      fontSize="18"
                    />
                  ) : (
                    <EyeIcon
                      className="text-gray-500 dark:text-gray-400 h-[1em] w-[1em]"
                      fontSize="18"
                    />
                  )}
                </button>
              </div>
              {errors.password && (
                <span className="text-xs text-red-500">
                  {errors.password.message}
                </span>
              )}
              <label className="label">
                <span className="label-text"></span>
                <a
                  className="label-text text-xs text-gray-600 dark:text-gray-400 hover:underline"
                  href="/forgot-password"
                >
                  {t("login.forgotPassword")}
                </a>
              </label>
            </div>
          </div>
          <div className="mt-2">
            <Button
              id="submit"
              type="submit"
              className="btn-md btn-block"
              disabled={loading || disabled}
              outline
            >
              {loading ? <LoadingSpinner size="sm" /> : <LoginIcon />}
              <span className="text-base">{t("login.signIn")}</span>
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

const LoginIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      fontSize="16"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4m-5-4l5-5l-5-5m5 5H3"
      ></path>
    </svg>
  );
};
