/* eslint-disable no-restricted-imports */
import { initGraphQLTada } from "gql.tada";
import { introspection } from "@/lib/data/graphql/graphql-env";

export const graphql = initGraphQLTada<{
  introspection: introspection;
  scalars: {
    DateTime: string;
  };
}>();

export type { FragmentOf, ResultOf, VariablesOf } from "gql.tada";
export { readFragment } from "gql.tada";
