import { useTranslation } from "@/lib/i18n";
import { logger } from "@/lib/logger";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu
} from "@/ui/form/dropdown";
import { FunnelIcon } from "@heroicons/react/24/outline";
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subMonths,
  subWeeks,
  subYears
} from "date-fns";
import { useCallback } from "react";

const FILTER_OPTIONS = [
  "today",
  "thisWeek",
  "thisMonth",
  "thisQuartal",
  "thisYear",
  "lastWeek",
  "lastMonth",
  "lastQuartal",
  "lastYear"
] as const;
type FilterOption = (typeof FILTER_OPTIONS)[keyof typeof FILTER_OPTIONS];

function getDateRange(filterOption: FilterOption): {
  startDate: Date | null;
  endDate: Date | null;
} {
  const today = startOfDay(new Date()); // Normalize to start of the current day

  switch (filterOption) {
    case "today": {
      const startDate = today;
      const endDate = endOfDay(today);
      return { startDate, endDate };
    }

    case "thisWeek": {
      const startDate = startOfWeek(today, { weekStartsOn: 1 }); // Start of the current week
      const endDate = endOfWeek(today, { weekStartsOn: 1 }); // End of the current week
      return { startDate, endDate };
    }

    case "thisMonth": {
      const startDate = startOfMonth(today);
      const endDate = endOfMonth(today);
      return { startDate, endDate };
    }

    case "thisQuartal": {
      const startDate = startOfQuarter(today);
      const endDate = endOfQuarter(today);
      return { startDate, endDate };
    }

    case "thisYear": {
      const startDate = startOfYear(today);
      const endDate = endOfYear(today);
      return { startDate, endDate };
    }

    case "lastWeek": {
      const startDate = startOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }); // Start of the last week
      const endDate = endOfWeek(subWeeks(today, 1), { weekStartsOn: 1 }); // End of the last week
      return { startDate, endDate };
    }

    case "lastMonth": {
      const startDate = startOfMonth(subMonths(today, 1));
      const endDate = endOfMonth(subMonths(today, 1));
      return { startDate, endDate };
    }

    case "lastQuartal": {
      const startDate = startOfQuarter(subMonths(today, 3));
      const endDate = endOfQuarter(subMonths(today, 3));
      return { startDate, endDate };
    }

    case "lastYear": {
      const startDate = startOfYear(subYears(today, 1));
      const endDate = endOfYear(subYears(today, 1));
      return { startDate, endDate };
    }

    default:
      logger.error("Invalid filter option");
      return { startDate: null, endDate: null };
  }
}
type DateFilterDropdownProps = {
  setDateFrom: (date: Date | null) => void;
  setDateTo: (date: Date | null) => void;
};

export const DateFilterDropdown: React.FC<DateFilterDropdownProps> = ({
  setDateFrom,
  setDateTo
}) => {
  const { t } = useTranslation();

  const handleOnCLick = useCallback(
    (filterOption: FilterOption) => {
      const { startDate, endDate } = getDateRange(filterOption);
      setDateFrom(startDate);
      setDateTo(endDate);
    },
    [setDateFrom, setDateTo]
  );

  return (
    <Dropdown>
      <DropdownButton outline>
        <FunnelIcon />
      </DropdownButton>
      <DropdownMenu>
        {FILTER_OPTIONS.map((filterOption) => {
          return (
            <DropdownItem
              key={filterOption}
              onClick={() => handleOnCLick(filterOption)}
            >
              {t(
                `documents.listing.filters.dateDropdown.option.${filterOption}`
              )}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};
