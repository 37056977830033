type OrderByState<T extends string> = Partial<Record<T, "ASC" | "DESC" | null>>;

export const useOrderBy = <T extends string>(
  setOrderBy: (
    updateFn: (prev: OrderByState<T> | null) => OrderByState<T> | null
  ) => void
) => {
  const onOrderBy = (column: T) => {
    setOrderBy((prev) => {
      const currentDirection = prev?.[column];

      const newDirection =
        currentDirection === "ASC"
          ? "DESC"
          : currentDirection === "DESC"
            ? null
            : "ASC";

      return newDirection === null
        ? null
        : ({ [column]: newDirection } as OrderByState<T>);
    });
  };

  return { onOrderBy };
};
