import { Routes } from "@/app/routes";
import { GraphqlProvider } from "@/lib/api/GraphqlProvider";
import { MeProvider, SessionProvider } from "@/lib/auth";
import { PushNotificationProvider } from "@/lib/push-notifications/PushNotificationsProvider";
import { RouterProvider } from "@/lib/routing/RouterProvider";
import { mockRequest } from "@/mocks";
import { ThemeProvider } from "@/ui/Theme";
import * as Sentry from "@sentry/capacitor";
import { FC } from "react";

Sentry.init({ dsn: import.meta.env.VITE_GLITCHTIP_DSN });

export const App: FC = () => {
  return (
    <SessionProvider>
      <GraphqlProvider
        mockRequest={mockRequest}
        apiUrl={import.meta.env.VITE_API_URL}
        clientName={`solarify-app-${import.meta.env.MODE}`}
      >
        <MeProvider>
          <PushNotificationProvider>
            <ThemeProvider>
              <RouterProvider>
                <Routes />
              </RouterProvider>
            </ThemeProvider>
          </PushNotificationProvider>
        </MeProvider>
      </GraphqlProvider>
    </SessionProvider>
  );
};
