import { AuthError } from "@/lib/auth";
import { mapFirebaseErrorKey } from "@/lib/auth/firebaseErrorTranslator";
import { useRecaptcha } from "@/lib/auth/multi-factor-authentication/useRecaptcha";
import { useTranslation } from "@/lib/i18n";
import { UiSteps } from "@/ui/steps/UiSteps";
import { PhoneMultiFactorGenerator } from "firebase/auth";
import { atom, useAtom } from "jotai";
import { PropsWithChildren, useState } from "react";
import {
  factorIdAtom,
  secondFactorFlowAtom,
  secondFactorStepAtom
} from "../atoms/mfaAtoms";
import { BackToLoginLink } from "../BackToLoginLink";
import { EnrollPhoneForm } from "./EnrollPhoneForm";
import { SelectSecondFactorForm } from "./SelectSecondFactorForm";
import { SmsVerificationForm } from "./SmsVerificationForm";

const currentStep = atom(
  (get) => get(secondFactorStepAtom) ?? "select_second_factor"
);

export const EnrollSecondFactorFlow: React.FC = () => {
  const { t } = useTranslation();
  const recaptcha = useRecaptcha();
  const [, setFlow] = useAtom(secondFactorFlowAtom);
  const [step] = useAtom(currentStep);
  const [, setStep] = useAtom(secondFactorStepAtom);
  const [factorId] = useAtom(factorIdAtom);

  const steps = ["select_second_factor", "enroll", "verify", "completed"];

  const [error, setError] = useState<string | null>(null);

  const getStepIndex = (step: string | null) => {
    if (!step) {
      return -1;
    }
    return steps.indexOf(step);
  };

  const handleError = (err: unknown) => {
    const errorKey = mapFirebaseErrorKey(err as AuthError);
    const errorMessage = t(`errors.auth.${errorKey}`, {
      ns: "common"
    } as unknown as string);

    setError(errorMessage);
  };

  const WhichEnrollment = () => {
    if (factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      return (
        <EnrollPhoneForm
          onSuccess={() => {
            setStep("verify");
          }}
          onError={handleError}
        />
      );
    }
    // Default to verify step
    setStep("verify");
    return null;
  };

  return (
    <div>
      {error ? <ErrorBox>{error}</ErrorBox> : null}

      {recaptcha.error && <ErrorBox>{recaptcha.error.message}</ErrorBox>}

      <div className="w-full">
        <UiSteps
          steps={steps.slice(0, steps.length - 1)}
          currentIndex={getStepIndex(step)}
          completeContent="✓"
          className="w-full"
        />
      </div>

      <div>
        {step === "select_second_factor" ? (
          <SelectSecondFactorForm
            onSuccess={() => {
              setStep("enroll");
            }}
            onError={handleError}
          />
        ) : null}

        {step === "enroll" ? <WhichEnrollment /> : null}

        {step === "verify" ? (
          <SmsVerificationForm
            onSuccess={async () => {
              setStep("completed");
              recaptcha.clear();
              await new Promise((resolve) => setTimeout(resolve, 1000));
              setFlow(null);
              setStep(null);
            }}
            onError={handleError}
          />
        ) : null}
      </div>

      {step === "completed" ? (
        <div className="mt-8">
          <div className="text-green-600 text-center">
            {t("mfa.completed.success.enroll")}
          </div>
        </div>
      ) : null}

      {step !== "completed" ? (
        <div className="mt-1">
          <BackToLoginLink />
        </div>
      ) : null}
    </div>
  );
};

const ErrorBox = ({ children }: PropsWithChildren) => {
  return (
    <div className="my-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
      {children}
    </div>
  );
};
