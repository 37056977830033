"use client";

import {
  ControllerRenderProps,
  type FieldError,
  FieldPath,
  type FieldValues,
  useController,
  UseControllerProps
} from "react-hook-form";
import React from "react";
import { ErrorMessage, Field, Label } from "@/ui/form/fieldset";
import { useTranslation } from "@/lib/i18n";

export type FormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  id: string;
  name: string;
  label?: string;
  optional?: boolean; // default required field
  error?: FieldError;
  defaultValue?: TFieldValues[TName];
  render?: (p: {
    field: ControllerRenderProps<TFieldValues, TName>;
    resourceId?: string;
    name: TName;
    error?: FieldError;
  }) => React.ReactNode;
};

export const FormFieldController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  label,
  name,
  defaultValue,
  optional,
  control,
  render,
  rules
}: FormFieldProps<TFieldValues, TName>) => {
  const { t } = useTranslation();

  const {
    field,
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue,
    rules: {
      ...rules,
      ...(optional ? {} : { required: t("form.mandatoryField") })
    }
  });

  return (
    <Field>
      {label ? <Label htmlFor={id}>{label}</Label> : null}

      {render?.({ field, name, error })}

      {error?.message ? <ErrorMessage>{error.message}</ErrorMessage> : null}
    </Field>
  );
};
