import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import {
  Auth,
  AuthError,
  GoogleAuthProvider,
  signInWithCredential,
  UserCredential
} from "firebase/auth";
import { useState } from "react";
import { AuthActionHook } from "./types";

export const useSignInWithGoogle = (
  auth: Auth
): AuthActionHook<() => Promise<UserCredential>> => {
  const [result, setResult] = useState<UserCredential>();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);
  /**
   * Because we are skipping auth on native layer, this is the way
   * how to sign in with Google on the web layer.
   */
  const signInWithGoogle = async () => {
    setLoading(true);
    setError(undefined);

    try {
      /**
       * 1. Create credentials on the native layer
       */
      const nativeLayer = await FirebaseAuthentication.signInWithGoogle({
        mode: "popup",
        /**
         * Authentication on native layer is disabled in order to make Firebase SMS 2FA work.
         * SMS 2FA is not supported on iOS, see github issue: https://github.com/capawesome-team/capacitor-firebase/issues/346
         */
        skipNativeAuth: true
      });

      /**
       * 2. Sign in on the web layer using the id token
       */
      const credential = GoogleAuthProvider.credential(
        nativeLayer.credential?.idToken
      );

      const result = await signInWithCredential(auth, credential);
      setResult(result);

      return result;
    } catch (err) {
      setError(error as AuthError);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return [signInWithGoogle, result, loading, error];
};
