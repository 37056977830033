export const DocumentsIcon = () => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-slot="icon"
    >
      <path d="M11.25 19.375H3.75C3.0625 19.375 2.5 18.8125 2.5 18.125V7.5H3.75V18.125H11.25V19.375Z" />
      <path d="M13.75 16.875H6.25C5.5625 16.875 5 16.3125 5 15.625V5H6.25V15.625H13.75V16.875Z" />
      <path d="M10 10H15V11.25H10V10Z" />
      <path d="M17.3125 5.8125L12.9375 1.4375C12.8125 1.3125 12.6875 1.25 12.5 1.25H8.75C8.0625 1.25 7.5 1.8125 7.5 2.5V13.125C7.5 13.8125 8.0625 14.375 8.75 14.375H16.25C16.9375 14.375 17.5 13.8125 17.5 13.125V6.25C17.5 6.0625 17.4375 5.9375 17.3125 5.8125ZM12.5 2.75L16 6.25H12.5V2.75ZM16.25 13.125H8.75V2.5H11.25V6.25C11.25 6.9375 11.8125 7.5 12.5 7.5H16.25V13.125Z" />
    </svg>
  );
};
