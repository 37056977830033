import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { formatDate } from "@/lib/utils/date";
import { Button } from "@/ui/button/button";
import { Field, Label } from "@/ui/form/fieldset";
import { Input, InputGroup } from "@/ui/form/input";
import { PlantsFilter } from "@/app/performance/PlantsFilter";
import { DateInput } from "@/ui/form/DateInput";
import { DateFilterDropdown } from "@/ui/table/DateFilterDropdown";
import { Select } from "@/ui/form/select";
import { TableFilterWrapper } from "@/ui/table/TableFilterWrapper";
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/outline";
import { compareAsc, formatISO, parse } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// yup, this is a scalar type, and you cannot expose it as enum (map fce) with values from gql.tada
type DocumentType = ReturnType<typeof graphql.scalar<"DocumentType">>;
const DOC_TYPE: DocumentType[] = ["BILL", "CONTRACT", "TAX_RECEIPT"] as const;

const parseDateFromUrl = (
  searchParams: URLSearchParams,
  key: string
): Date | null => {
  const paramValue = searchParams.get(key);
  return paramValue ? parse(paramValue, "dd.MM.yyyy", new Date()) : null;
};

const getDateISO = (date: Date | null): string | null =>
  date ? formatISO(date) : null;

type SetDocumentsWhere = (where: {
  dateFrom?: string | null;
  dateTo?: string | null;
  name?: string | null;
  plantId?: string | null;
  type?: DocumentType | null;
}) => void;

type DocumentsFiltersProps = {
  setWhere: SetDocumentsWhere;
};

export const DocumentsFilters: React.FC<DocumentsFiltersProps> = ({
  setWhere
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    plantId: searchParams.get("plant") ?? "all",
    name: searchParams.get("name") ?? "",
    type: searchParams.get("type") ?? "",
    dateFrom: parseDateFromUrl(searchParams, "dateFrom"),
    dateTo: parseDateFromUrl(searchParams, "dateTo")
  });

  // sync with url
  useEffect(() => {
    const updatedParams = new URLSearchParams(searchParams);
    const filtersToParams = {
      plant: filters.plantId,
      name: filters.name,
      type: filters.type,
      dateFrom: filters.dateFrom ? formatDate(filters.dateFrom) : null,
      dateTo: filters.dateTo ? formatDate(filters.dateTo) : null
    };

    // Iterate through all filters and update search params
    Object.entries(filtersToParams).forEach(([key, value]) => {
      if (!value || value === "all") {
        updatedParams.delete(key);
      } else if (value) {
        updatedParams.set(key, value.toString());
      }
    });

    setSearchParams(updatedParams);
  }, [filters, searchParams, setSearchParams]);

  const filtersActive = Boolean(
    filters.name ||
      filters.plantId !== "all" ||
      filters.type !== "all" ||
      filters.dateFrom ||
      filters.dateTo
  );

  const resetFilters = useCallback(() => {
    setFilters({
      plantId: "all",
      type: "all",
      name: "",
      dateFrom: null,
      dateTo: null
    });
  }, []);

  useEffect(() => {
    if (
      filters.dateFrom &&
      filters.dateTo &&
      compareAsc(filters.dateFrom, filters.dateTo) === 1
    ) {
      setFilters((prev) => ({ ...prev, dateTo: null }));
    }
  }, [filters.dateFrom, filters.dateTo]);

  useUpdateGqlQueryParams(setWhere, filters);

  return (
    <TableFilterWrapper>
      <Field className="min-w-36">
        <Label>{t("documents.listing.filters.name.label")}</Label>
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            name="search"
            placeholder={t("documents.listing.filters.name.placeholder")}
            aria-label={t("documents.listing.filters.name.placeholder")}
            value={filters.name}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, name: e.target.value }))
            }
          />
        </InputGroup>
      </Field>

      <Field className="min-w-60">
        <Label>{t("documents.listing.filters.plant.label")}</Label>
        <PlantsFilter
          className="mt-2"
          onChange={(value) =>
            setFilters((prev) => ({ ...prev, plantId: value }))
          }
          defaultValueId={filters.plantId}
        />
      </Field>

      <Field className="min-w-36">
        <Label>{t("documents.listing.filters.type.label")}</Label>
        <Select
          name="type"
          value={filters.type}
          onChange={(e) =>
            setFilters((prev) => ({
              ...prev,
              type: e.target.value as DocumentType
            }))
          }
        >
          <option value="all">
            {t("documents.listing.filters.type.options.all")}
          </option>
          {DOC_TYPE.map((Document) => (
            <option key={Document} value={Document}>
              {t(`documents.listing.filters.type.options.${Document}`)}
            </option>
          ))}
        </Select>
      </Field>

      <div className="flex xl:ml-auto flex-wrap gap-4">
        <Field className="min-w-10">
          <Label>{t("documents.listing.filters.dateFrom.label")}</Label>
          <DateInput
            value={filters.dateFrom}
            onChange={(date) =>
              setFilters((prev) => ({ ...prev, dateFrom: date }))
            }
            className="mt-3"
            prefix={t("documents.listing.filters.dateFrom.prefix")}
            placeholder={t("documents.listing.filters.dateFrom.placeholder")}
          />
        </Field>

        <Field className="min-w-10">
          <Label>{t("documents.listing.filters.dateTo.label")}</Label>
          <DateInput
            value={filters.dateTo}
            minDate={filters.dateFrom ?? undefined}
            onChange={(date) =>
              setFilters((prev) => ({ ...prev, dateTo: date }))
            }
            className="mt-3"
            prefix={t("documents.listing.filters.dateTo.prefix")}
            placeholder={t("documents.listing.filters.dateTo.placeholder")}
          />
        </Field>

        <Field className="mt-auto">
          <DateFilterDropdown
            setDateTo={(date) =>
              setFilters((prev) => ({ ...prev, dateTo: date }))
            }
            setDateFrom={(date) =>
              setFilters((prev) => ({ ...prev, dateFrom: date }))
            }
          />
        </Field>

        <Field className="mt-auto">
          <Button outline disabled={!filtersActive} onClick={resetFilters}>
            <ArrowUturnLeftIcon />
          </Button>
        </Field>
      </div>
    </TableFilterWrapper>
  );
};

const useUpdateGqlQueryParams = (
  setWhere: SetDocumentsWhere,
  filters: {
    plantId: string;
    name: string;
    type: string;
    dateFrom: Date | null;
    dateTo: Date | null;
  }
) => {
  useEffect(() => {
    const { name, plantId, type, dateFrom, dateTo } = filters;

    setWhere({
      dateFrom: getDateISO(dateFrom),
      dateTo: getDateISO(dateTo),
      name: name || null,
      plantId: plantId === "all" ? null : plantId ?? null,
      type:
        type === "all"
          ? null
          : DOC_TYPE.includes(type as DocumentType)
            ? (type as DocumentType)
            : null
    });
  }, [filters, setWhere]);
};
