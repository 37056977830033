import { Field, Legend } from "@/ui/form/fieldset";
import { graphql } from "@/lib/data/graphql";
import { useQuery } from "@apollo/client";
import { useTranslation } from "@/lib/i18n";
import { CheckboxGroupBox } from "@/ui/form/CheckboxGroupBox";
import { FieldPath, FieldValues, UseControllerProps } from "react-hook-form";

export const UserRolesRadioFieldQuery = graphql(`
  query UserRolesRadioField {
    adminRoles {
      id
      name
    }
  }
`);

type UserRolesRadioFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName>;

export const UserRolesRadioField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  control,
  name
}: UserRolesRadioFieldProps<TFieldValues, TName>) => {
  const { t } = useTranslation();
  const { data, loading } = useQuery(UserRolesRadioFieldQuery);
  const roles = data?.adminRoles || [];

  if (loading || roles.length === 0) {
    return null;
  }

  return (
    <Field>
      <Legend className="mb-2">
        {t("userManagement.editForm.fields.roles.label")}
      </Legend>
      <CheckboxGroupBox
        id="roles"
        name={name}
        control={control}
        options={roles.map((r) => ({
          label: r.name.toString(),
          value: r.id.toString()
        }))}
        optional
      />
    </Field>
  );
};
