import { useSignOut } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";

export const BackToLoginLink: React.FC = () => {
  const { t } = useTranslation();
  const [signOut] = useSignOut();

  return (
    <label className="label">
      <span className="label-text"></span>
      <a
        className="label-text text-xs text-gray-600 dark:text-gray-400 hover:underline"
        onClick={() => {
          // Force login page by signing out from current session
          signOut();
        }}
        href="/"
      >
        {t("mfa.button.backToLogin")}
      </a>
    </label>
  );
};
