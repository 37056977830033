import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useDeeplinks = () => {
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const url = new URL(event.url);
      const path = `${url.pathname}${url.search}${url.hash}`;
      navigate(path);
    });
  }, [navigate]);

  return null;
};
