import { getObject, setObject } from "@/lib/utils/preferences";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

const THEME_KEY = "theme";
const ThemeValues = ["system", "dark", "light"] as const;
type Theme = (typeof ThemeValues)[number];

type ThemeContextType = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  toggleTheme: () => void;
};

const ThemeContext = createContext<ThemeContextType | null>(null);

function updateDocumentTheme(theme: Theme) {
  document.documentElement.classList.toggle("dark", theme === "dark");
}

export const ThemeProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const [theme, setTheme] = useState<Theme | null>(null);

  useEffect(() => {
    const loadInitialTheme = async () => {
      const savedTheme = (await getObject(THEME_KEY)) || "system";
      updateDocumentTheme(savedTheme);
      setTheme(savedTheme);
    };

    loadInitialTheme();
  }, []);

  // TODO: are we using this somewhere?:D option to set theme by system
  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleSystemThemeChange = () => {
      if (theme === "system") {
        const systemTheme = mediaQuery.matches ? "dark" : "light";
        updateDocumentTheme(systemTheme);
        setTheme(systemTheme);
      }
    };
    handleSystemThemeChange();

    mediaQuery.addEventListener("change", handleSystemThemeChange);
    return () =>
      mediaQuery.removeEventListener("change", handleSystemThemeChange);
  }, [theme]);

  const changeTheme = useCallback((newTheme: Theme) => {
    setTheme(newTheme);
    setObject(THEME_KEY, newTheme);
    updateDocumentTheme(newTheme);
  }, []);

  const toggleTheme = () => changeTheme(theme === "dark" ? "light" : "dark");

  if (!theme) {
    return;
  }

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme: changeTheme, toggleTheme }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
