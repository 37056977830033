import { logger } from "@/lib/logger";
import {
  type Auth,
  getMultiFactorResolver,
  multiFactor,
  type MultiFactorError,
  type MultiFactorResolver,
  PhoneAuthProvider,
  type PhoneInfoOptions,
  type PhoneMultiFactorEnrollInfoOptions,
  PhoneMultiFactorGenerator,
  type RecaptchaVerifier,
  type User
} from "firebase/auth";
import { createContext, FC, PropsWithChildren, useState } from "react";

export type MultiFactorAuthProviderProps = {
  auth: Auth;
};

export type MultiFactorAuthProviderContextType = {
  resolver: MultiFactorResolver | null;
  verificationId: string | null;
  enrollPhoneNumber: (
    phoneNumber: string,
    user: User | null,
    recaptcha: RecaptchaVerifier | null
  ) => Promise<void>;
  recoverFromError: (error: MultiFactorError | null) => MultiFactorResolver;
  sendVerificationCode: (
    resolver: MultiFactorResolver | null,
    recaptcha: RecaptchaVerifier | null
  ) => Promise<string>;
  verifyPhoneEnroll: (
    code: string,
    user: User | null,
    verificationId: string | null
  ) => Promise<void>;
  verifySmsCode: (code: string) => Promise<void>;
};

const MultiFactorAuthContext =
  createContext<MultiFactorAuthProviderContextType | null>(null);

const MultiFactorAuthProvider: FC<
  PropsWithChildren<MultiFactorAuthProviderProps>
> = ({ auth, children }) => {
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(null);
  const [verificationId, setVerificationId] = useState<string | null>(null);

  const recoverFromError = (
    error: MultiFactorError | null
  ): MultiFactorResolver => {
    if (!error) {
      throw new Error("MFA: Error to recover from is not defined");
    }

    try {
      const resolver = getMultiFactorResolver(auth, error);
      setResolver(() => resolver);

      return resolver;
    } catch (error) {
      logger.error("getMultiFactorResolver failed", error);
      throw error;
    }
  };

  const enrollPhoneNumber = async (
    phoneNumber: string,
    user: User | null,
    recaptcha: RecaptchaVerifier | null
  ) => {
    if (!recaptcha) {
      throw new Error("MFA: Recaptcha is not initialized");
    }

    if (!user) {
      throw new Error("MFA: User is not defined");
    }

    const session = await multiFactor(user).getSession();

    const phoneOptions: PhoneMultiFactorEnrollInfoOptions = {
      phoneNumber,
      session
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);

    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneOptions,
      recaptcha
    );
    setVerificationId(verificationId);
  };

  const sendVerificationCode = async (
    resolver: MultiFactorResolver | null,
    recaptcha: RecaptchaVerifier | null
  ): Promise<string> => {
    if (!resolver) {
      throw new Error("MFA: Resolver is not initialized");
    }

    if (!recaptcha) {
      throw new Error("MFA: Recaptcha is not initialized");
    }

    const phoneInfoOptions: PhoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);

    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptcha
    );

    setVerificationId(verificationId);

    return verificationId;
  };

  const verifyPhoneEnroll = async (
    smsCode: string,
    user?: User | null,
    verificationId?: string | null
  ) => {
    if (!verificationId) {
      throw new Error("Verification Id is not available");
    }

    if (!user) {
      throw new Error("User is not available");
    }

    const credentials = PhoneAuthProvider.credential(verificationId, smsCode);
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credentials);

    await multiFactor(user).enroll(multiFactorAssertion, "Phone number");
    await user.getIdToken(true);
  };

  const verifySmsCode = async (smsCode: string) => {
    if (!verificationId) {
      throw new Error("Verification ID is not available");
    }

    if (!resolver) {
      throw new Error("Resolver is not available");
    }

    const credential = PhoneAuthProvider.credential(verificationId, smsCode);
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credential);

    await resolver.resolveSignIn(multiFactorAssertion);
  };

  return (
    <MultiFactorAuthContext.Provider
      value={{
        resolver,
        verificationId,
        enrollPhoneNumber,
        recoverFromError,
        sendVerificationCode,
        verifyPhoneEnroll,
        verifySmsCode
      }}
    >
      {children}
    </MultiFactorAuthContext.Provider>
  );
};

export { MultiFactorAuthContext, MultiFactorAuthProvider };
