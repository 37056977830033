import { useTranslation } from "@/lib/i18n";
import { useQuery } from "@apollo/client";
import { graphql } from "@/lib/data/graphql";
import { useState } from "react";
import {
  PlantPerformance,
  PlantPerformanceFragment
} from "../performance/PlantPerformance";
import { PlantsFilter } from "../performance/PlantsFilter";
import { Heading } from "@/ui/typo/heading";
import { Select } from "@/ui/form/select";
import { GraphqlErrorAlert } from "@/ui/error/GraphqlErrorAlert";

export const AdminDashboardPageQuery = graphql(
  `
    query AdminDashboardPage($plantId: Int!) {
      plant(plantId: $plantId) {
        ...PlantPerformance
      }
    }
  `,
  [PlantPerformanceFragment]
);

const CURRENT_YEAR = new Date().getFullYear();

export const AdminDashboardPage = () => {
  const [year, setYear] = useState(CURRENT_YEAR);
  const [quarter, setQuarter] = useState(1);
  const [plantId, setPlantId] = useState<string | undefined>();
  const { loading, error, data } = useQuery(AdminDashboardPageQuery, {
    variables: { plantId: parseInt(plantId ?? "0", 10) },
    skip: !plantId
  });
  const { t } = useTranslation();

  if (error) {
    return <GraphqlErrorAlert error={error} />;
  }

  return (
    <>
      <div className="mt-8 flex flex-wrap md:items-end justify-between flex-col md:flex-row">
        <Heading>{t("dashboard.title")}</Heading>
        <div className="flex flex-col md:flex-row md:gap-4">
          <PlantsFilter
            className="sm:w-96 mt-2"
            onChange={setPlantId}
            selectFirstEntry
          />
          <div className="flex gap-4">
            <Select
              name="period"
              value={year}
              className="sm:w-32 mt-2"
              onChange={(e) => setYear(Number(e.target.value))}
            >
              {[...Array(5)].map((_, index) => {
                const optionYear = CURRENT_YEAR - index;
                return (
                  <option key={optionYear} value={optionYear}>
                    {optionYear}
                  </option>
                );
              })}
            </Select>
            <Select
              name="quarter"
              className="sm:w-32 mt-2"
              value={quarter}
              onChange={(e) => setQuarter(Number(e.target.value))}
            >
              {[...Array.from({ length: 4 })].map((_, i) => {
                const quarter = `Q${i + 1}`;

                return (
                  <option key={quarter} value={i + 1}>
                    {quarter}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
      <PlantPerformance
        data={data?.plant}
        year={year}
        quarter={quarter}
        loading={loading}
      />
    </>
  );
};
