import { useContext } from "react";
import { RecaptchaProviderContext } from "./RecaptchaProvider";

export const useRecaptcha = () => {
  const context = useContext(RecaptchaProviderContext);
  if (!context) {
    throw new Error(
      "useRecaptcha must be used within a RecaptchaProviderContext"
    );
  }
  return context;
};
