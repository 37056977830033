import clsx from "clsx";
import { FC } from "react";

export type LoadingSpinnerProps = {
  size?: "sm" | "lg";
};

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = "lg" }) => {
  const styles = {
    sizes: {
      sm: ["loading-sm"],
      lg: ["loading-lg"]
    }
  };

  const classes = clsx("loading loading-spinner", styles.sizes[size]);

  return <div className={classes}></div>;
};
