import { FieldPath, FieldValues } from "react-hook-form";
import { FormFieldController, FormFieldProps } from "./FormFieldController";
import { Label } from "@/ui/form/fieldset";
import { Checkbox, CheckboxField, CheckboxGroup } from "@/ui/form/checkbox";

type CheckboxOption = {
  value: string;
  label: string;
};

type GroupBoxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = FormFieldProps<TFieldValues, TName> & { options: CheckboxOption[] };

export const CheckboxGroupBox = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  id,
  label,
  control,
  name,
  options,
  optional
  // ...restProps
}: GroupBoxProps<TFieldValues, TName>) => {
  if (!options) {
    return null;
  }

  return (
    <FormFieldController
      id={id}
      label={label}
      control={control}
      name={name}
      optional={optional}
      render={({ field }) => (
        <CheckboxGroup>
          {options.map((option) => (
            <CheckboxField key={option.value}>
              <Checkbox
                name={field.name}
                value={option.value}
                checked={field.value?.includes(option.value)}
                onChange={(isChecked) => {
                  let updatedValues: string[] = field.value ?? [];

                  if (isChecked) {
                    updatedValues = [...updatedValues, option.value];
                  } else {
                    updatedValues = updatedValues.filter(
                      (item) => item !== option.value
                    );
                  }

                  // Update the form value
                  field.onChange(updatedValues);
                }}
              />
              <Label className="cursor-pointer">{option.label}</Label>
            </CheckboxField>
          ))}
        </CheckboxGroup>
      )}
    />
  );
};
