import { logger } from "@/lib/logger";
import { Auth, AuthError, RecaptchaVerifier } from "firebase/auth";
import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState
} from "react";

export type RecaptchaProviderProps = {
  auth: Auth;
  containerId: string;
  elementRef: HTMLDivElement | null;
};

export type RecaptchaProviderContextType = {
  recaptchaVerifier: RecaptchaVerifier | null;
  error: AuthError | Error | null;
  initializeInvisible: (
    auth: Auth,
    element?: HTMLElement | null,
    containerId?: string
  ) => void;
  render: () => Promise<void>;
  clear: () => void;
};

const RecaptchaProviderContext =
  createContext<RecaptchaProviderContextType | null>(null);

const RecaptchaProvider: FC<PropsWithChildren<RecaptchaProviderProps>> = ({
  auth,
  containerId,
  elementRef,
  children
}) => {
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);
  const [error, setError] = useState<AuthError | Error | null>(null);

  useEffect(() => {
    initializeInvisible(auth, elementRef, containerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeInvisible = (
    auth: Auth,
    elementRef?: HTMLElement | null,
    containerId?: string
  ): void => {
    try {
      const verifier = new RecaptchaVerifier(auth, elementRef ?? containerId!, {
        size: "invisible",
        callback: () => {
          // On solved
        },
        "expired-callback": () => {
          setError(new Error("reCAPTCHA response expired"));
        },
        "error-callback": (error: unknown) => {
          logger.error("Error verifying reCAPTCHA:", error);
          setError(new Error("Error verifying reCAPTCHA"));
        }
      });
      setRecaptchaVerifier(verifier);
    } catch (err) {
      logger.error("Error initializing reCAPTCHA:", err);
      setError(new Error("Error initializing reCAPTCHA"));
    }
  };

  const render = async () => {
    if (!recaptchaVerifier) {
      logger.error("RecaptchaVerifier not initialized");
      return;
    }

    try {
      await recaptchaVerifier.render();
    } catch (error) {
      logger.error("Error rendering reCAPTCHA:", error);
      setError(error as AuthError);
    }
  };

  const clear = () => {
    if (!recaptchaVerifier) {
      logger.error("RecaptchaVerifier not initialized");
      return;
    }

    recaptchaVerifier.clear();
    setRecaptchaVerifier(null);
  };

  return (
    <RecaptchaProviderContext.Provider
      value={{
        recaptchaVerifier,
        error,
        initializeInvisible,
        render,
        clear
      }}
    >
      {children}
    </RecaptchaProviderContext.Provider>
  );
};

export { RecaptchaProvider, RecaptchaProviderContext };
