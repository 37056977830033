import { useTranslation } from "@/lib/i18n";
import { useQuery } from "@apollo/client";
import { graphql } from "@/lib/data/graphql";
import { ListingPageWrapper } from "@/ui/ListingPageWrapper";
import { TableLoader } from "@/ui/table/TableLoader";
import { GraphqlErrorAlert } from "@/ui/error/GraphqlErrorAlert";
import { Heading } from "@/ui/typo/heading";
import { Divider } from "@/ui/divider";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/ui/table/table";

export const PlantsPageQuery = graphql(
  `
    query PlantsPageQuery {
      plants {
        id
        name
        performances {
          pYield
          pConsumption
          pOwnConsumption
        }
      }
    }
  `,
  []
);

export const PlantsPage = () => {
  const { loading, error, data } = useQuery(PlantsPageQuery);
  const { t } = useTranslation();

  return (
    <ListingPageWrapper>
      <Heading>{t("plants.listing.title")}</Heading>
      <Divider />

      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>{t("plants.listing.fields.id")}</TableHeader>
            <TableHeader>{t("plants.listing.fields.name")}</TableHeader>
            <TableHeader>{t("plants.listing.fields.currentYield")}</TableHeader>
            <TableHeader>{t("plants.listing.fields.consumption")}</TableHeader>
            <TableHeader>
              {t("plants.listing.fields.ownConsumption")}
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {error ? (
            <GraphqlErrorAlert error={error} />
          ) : data ? (
            data.plants.map((plant) => (
              <TableRow key={plant.id}>
                <TableCell className="font-medium">{plant.id}</TableCell>
                <TableCell>{plant.name}</TableCell>
                <TableCell className="text-zinc-500">
                  {plant.performances[0]?.pYield}
                </TableCell>
                <TableCell className="text-zinc-500">
                  {plant.performances[0]?.pConsumption}
                </TableCell>
                <TableCell className="text-zinc-500">
                  {plant.performances[0]?.pOwnConsumption}
                </TableCell>
              </TableRow>
            ))
          ) : loading ? (
            <TableLoader colSpan={5} />
          ) : null}
        </TableBody>
      </Table>
    </ListingPageWrapper>
  );
};
