import clsx from "clsx";
import React from "react";

export const Divider = ({
  soft = false,
  className,
  ...props
}: { className?: string } & {
  soft?: boolean;
} & React.ComponentPropsWithoutRef<"hr">) => {
  return (
    <hr
      role="presentation"
      {...props}
      className={clsx(
        className,
        "w-full border-t",
        soft && "border-zinc-950/5 dark:border-white/5",
        !soft && "border-zinc-950/10 dark:border-white/10"
      )}
    />
  );
};
