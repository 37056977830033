import { useAddResource } from "@/app/permissions/hooks/useAddPermission";
import { useUpdateRole } from "@/app/permissions/hooks/useUpdateRole";
import { useTranslation } from "@/lib/i18n";
import { logger } from "@/lib/logger";
import { Button } from "@/ui/button/button";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "@/ui/dialog";
import { InputField } from "@/ui/form/InputField";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { toaster } from "@/ui/toast/Toaster";
import { PlusIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";
import { useForm } from "react-hook-form";

type FormData = {
  name: string;
};
type Props = {
  subjectActionMap: Record<string, Set<string>>;
  selectedRoleId?: string;
};

export const AddPermissionDialogBtn: React.FC<Props> = ({
  subjectActionMap,
  selectedRoleId
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const { addResource, loading: loadingAddResource } = useAddResource();
  const { updateRole, loading: loadingUpdateRole } = useUpdateRole();

  const { control, reset, handleSubmit } = useForm<FormData>({
    defaultValues: { name: "" }
  });
  const loading = loadingAddResource || loadingUpdateRole;

  const handleAddPermission = async (formData: FormData) => {
    await addResource({
      variables: { name: formData.name }
    });

    const resources = Object.entries(subjectActionMap).flatMap(([sub, acts]) =>
      Array.from(acts).map((act) => `${sub}:${act}`)
    );

    if (selectedRoleId) {
      updateRole({
        variables: {
          input: {
            roleId: parseInt(selectedRoleId, 10),
            resourceNames: [...resources, formData.name]
          }
        },
        onCompleted: () => {
          reset();
          setIsOpen(false);
        },
        onError: (error) => {
          toaster.error({
            description: t("permissions.addPermission.error.description")
          });
          logger.error(error);
        }
      });
    }
  };

  return (
    <>
      <Button
        outline
        className="ml-auto mt-auto"
        onClick={() => setIsOpen(true)}
      >
        {t("permissions.addPermission.newPermission")}
        <PlusIcon />
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{t("permissions.addPermission.title")}</DialogTitle>
        <form onSubmit={handleSubmit(handleAddPermission)}>
          <DialogBody>
            <InputField
              id="name"
              name="name"
              control={control}
              label={t("permissions.addPermission.fields.permissionName.label")}
              placeholder={t(
                "permissions.addPermission.fields.permissionName.placeholder"
              )}
            />
          </DialogBody>
          <DialogActions>
            <Button plain onClick={() => setIsOpen(false)}>
              {t("permissions.addPermission.actions.cancel")}
            </Button>
            <Button
              disabled={loading}
              className={clsx({ "pointer-events-none opacity-50": loading })}
              type="submit"
            >
              {loading ? (
                <div className="pr-2">
                  <LoadingSpinner size="sm" />
                </div>
              ) : null}
              {t("permissions.addPermission.actions.add")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
