import { useSession } from "@/lib/auth";
import { Avatar, AvatarProps } from "@/ui/avatar";

export const UserAvatar = (props: AvatarProps) => {
  const { me } = useSession();
  const { square = true, alt = "avatar", ...rest } = props;

  const getInitials = () => {
    if (me?.photoUrl) {
      return undefined;
    }

    if (me?.displayName) {
      return me.displayName
        .split(" ")
        .map((name) => name[0])
        .join("");
    } else {
      return me?.email?.charAt(0);
    }
  };

  if (me) {
    return (
      <Avatar
        src={me.photoUrl}
        initials={getInitials()}
        square={square}
        alt={alt}
        {...rest}
      />
    );
  }

  return <Avatar initials="..." square={square} alt={alt} {...rest} />;
};
