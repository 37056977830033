import { LANGUAGE_CODES, useTranslation } from "@/lib/i18n";
import { useState } from "react";
import {
  Dialog,
  DialogBody,
  DialogDescription,
  DialogTitle
} from "@/ui/dialog";
import { Field } from "@/ui/form/fieldset";
import { Select } from "@/ui/form/select";

type Props = {
  open: boolean;
  onClose: () => void;
};

export const LanguageDialog = (props: Props) => {
  const { open, onClose } = props;
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setSelectedLanguage(value);
    i18n.changeLanguage(value);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("languagesDialog.title")}</DialogTitle>
      <DialogDescription>{t("languagesDialog.description")}</DialogDescription>
      <DialogBody>
        <Field>
          <Select name="language" value={selectedLanguage} onChange={onChange}>
            {LANGUAGE_CODES.map((languageCode) => (
              <option key={languageCode} value={languageCode}>
                {t(`languages.${languageCode}`)}
              </option>
            ))}
          </Select>
        </Field>
      </DialogBody>
    </Dialog>
  );
};
