import { useTranslation } from "@/lib/i18n";
import { Link } from "@/ui/link";

export const NotAuthorized: React.FC = () => {
  const { t } = useTranslation();
  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        {/* eslint-disable-next-line react/jsx-no-literals */}
        <p className="text-base font-semibold">403</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
          {t("pageNotAuthorized.title")}
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600">
          {t("pageNotAuthorized.description")}
        </p>
        <Link href="/" className="underline hover:no-underline">
          {t("pageNotAuthorized.homeLink")}
        </Link>
      </div>
    </main>
  );
};
