import { useTheme } from "@/ui/Theme";

interface Props {
  width?: number;
  height?: number;
  className?: string;
}

export const Logo = (props: Props) => {
  const { width = 170, height = 36, className } = props;
  const { theme } = useTheme();

  const color = theme === "dark" ? "white" : "black";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 170 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.4737 0L27.1473 7.65008L24.569 10.2205L14.5833 0.26545C12.9559 0.576498 11.406 1.10491 9.96527 1.81943L21.4804 13.2994L18.9024 15.8696L6.80943 3.81364C5.66292 4.71633 4.62909 5.7544 3.73164 6.90331L15.8139 18.9488L13.2358 21.5189L1.74982 10.0683C1.04098 11.5147 0.520706 13.0699 0.220933 14.702L10.1471 24.5978L7.56898 27.168L0 19.6222C0.483252 24.9259 3.26983 29.5657 7.35517 32.5397L32.6224 7.34963C29.6033 3.22799 24.8734 0.431778 19.4737 0Z"
        fill={color}
      />
      <path
        d="M17.9731 32.3653C17.9024 32.3653 17.8321 32.3646 17.7614 32.3635L32.4107 17.7592C32.4117 17.8295 32.4121 17.8999 32.4121 17.9704C32.4121 25.9076 25.935 32.3653 17.9731 32.3653ZM36.0583 17.9704C36.0583 15.3544 35.4984 12.8688 34.4932 10.6252L10.6053 34.4396C12.856 35.4421 15.3491 36 17.9731 36C17.9791 36 17.9851 35.9997 17.991 35.9997C27.9709 35.9903 36.0583 27.922 36.0583 17.9704Z"
        fill={color}
      />
      <path
        d="M134.676 25.1499V11.0443H137.149V25.1499H134.676Z"
        fill={color}
      />
      <path
        d="M143.999 19.3098V17.1561H151.161V19.3098H143.999ZM139.736 25.1499V11.0443H151.745V13.5084H142.188V25.1499H139.736Z"
        fill={color}
      />
      <path
        d="M159.997 25.1499V19.7947L152.484 11.0443H155.812L161.32 17.544L166.847 11.0443H170L162.468 19.7947V25.1499H159.997Z"
        fill={color}
      />
      <path
        d="M47.6407 25.1499V22.6859H58.2477C58.9095 22.6859 59.4184 22.5178 59.7758 22.1814C60.1325 21.8451 60.3108 21.3991 60.3108 20.8426C60.3108 20.2346 60.1325 19.7787 59.7758 19.4747C59.4184 19.1707 58.9095 19.0185 58.2477 19.0185H51.7471C51.0857 19.0185 50.4854 18.9186 49.947 18.7181C49.4083 18.5176 48.9546 18.2396 48.5846 17.8835C48.2148 17.528 47.9294 17.1045 47.7283 16.6127C47.5269 16.1212 47.4265 15.5842 47.4265 15.0022C47.4265 14.4332 47.5205 13.906 47.7089 13.4209C47.897 12.9359 48.176 12.5187 48.5457 12.1694C48.9155 11.8203 49.3729 11.5455 49.9179 11.3449C50.463 11.1446 51.0857 11.0443 51.7863 11.0443H61.9456V13.5084H51.7863C51.2151 13.5084 50.7741 13.6605 50.463 13.9641C50.1515 14.2684 49.9959 14.6919 49.9959 15.235C49.9959 15.7785 50.1545 16.1988 50.4723 16.4963C50.7906 16.7939 51.2218 16.9425 51.7669 16.9425H58.2477C59.6881 16.9425 60.7809 17.279 61.5274 17.9515C62.2732 18.6241 62.6463 19.6462 62.6463 21.0172C62.6463 21.6122 62.5556 22.1618 62.3739 22.6663C62.1922 23.1708 61.9195 23.6074 61.5565 23.976C61.1931 24.3448 60.7357 24.6327 60.1843 24.8395C59.6329 25.0465 58.9875 25.1499 58.2477 25.1499H47.6407Z"
        fill={color}
      />
      <path
        d="M74.674 22.6859C75.3224 22.6859 75.9193 22.5693 76.4644 22.3365C77.0094 22.1037 77.4795 21.7836 77.8757 21.3761C78.2712 20.9686 78.5793 20.4804 78.8002 19.9113C79.0203 19.3421 79.1308 18.7275 79.1308 18.0679C79.1308 17.4083 79.0203 16.7971 78.8002 16.2344C78.5793 15.6717 78.2712 15.1899 77.8757 14.7888C77.4795 14.3881 77.0094 14.0744 76.4644 13.8479C75.9193 13.6215 75.3224 13.5084 74.674 13.5084H71.2289C70.5932 13.5084 70.006 13.6215 69.4676 13.8479C68.9293 14.0744 68.4652 14.3881 68.0764 14.7888C67.6869 15.1899 67.3821 15.6686 67.1613 16.2246C66.9408 16.7811 66.8307 17.3954 66.8307 18.0679C66.8307 18.7275 66.9408 19.3421 67.1613 19.9113C67.3821 20.4804 67.6869 20.9686 68.0764 21.3761C68.4652 21.7836 68.9293 22.1037 69.4676 22.3365C70.006 22.5693 70.5932 22.6859 71.2289 22.6859H74.674ZM71.2289 25.1499C70.2429 25.1499 69.3315 24.9654 68.4947 24.5968C67.6578 24.2285 66.9311 23.7239 66.3148 23.0837C65.6984 22.4432 65.2183 21.6834 64.8747 20.8037C64.5307 19.9244 64.3587 18.9735 64.3587 17.9515C64.3587 16.9168 64.5307 15.9724 64.8747 15.1187C65.2183 14.2649 65.6984 13.5374 66.3148 12.9359C66.9311 12.3345 67.6578 11.8688 68.4947 11.5388C69.3315 11.2089 70.2429 11.0443 71.2289 11.0443H74.674C75.6988 11.0443 76.6363 11.2124 77.4862 11.5486C78.3361 11.8851 79.0658 12.3571 79.6758 12.965C80.2854 13.5731 80.7592 14.3009 81.0965 15.1479C81.4338 15.9953 81.6024 16.9299 81.6024 17.9515C81.6024 18.9735 81.4304 19.9244 81.0868 20.8037C80.7428 21.6834 80.2627 22.4432 79.6467 23.0837C79.03 23.7239 78.3003 24.2285 77.4571 24.5968C76.6136 24.9654 75.6861 25.1499 74.674 25.1499H71.2289Z"
        fill={color}
      />
      <path
        d="M83.6069 25.1499V11.0443H86.0785V22.6859H95.5962V25.1499H83.6069Z"
        fill={color}
      />
      <path
        d="M112.119 25.1499L109.998 21.5991H103.303L104.471 19.6007H108.811L105.658 14.3231L99.2351 25.1499H96.4128L104.549 11.6455C104.691 11.4 104.86 11.206 105.054 11.0635C105.249 10.9212 105.483 10.8501 105.755 10.8501C106.028 10.8501 106.258 10.9212 106.446 11.0635C106.634 11.206 106.799 11.4 106.942 11.6455L115.097 25.1499H112.119Z"
        fill={color}
      />
      <path
        d="M129.752 25.1499L126.171 21.2305H120.878V19.0573H126.774C127.67 19.0573 128.344 18.8151 128.799 18.3299C129.253 17.8447 129.48 17.1432 129.48 16.2246C129.48 15.3064 129.243 14.6239 128.77 14.1777C128.296 13.7315 127.631 13.5084 126.774 13.5084H119.067V25.1499H116.615V11.0443H126.774C127.579 11.0443 128.299 11.1639 128.935 11.4032C129.57 11.6426 130.109 11.9852 130.55 12.4313C130.991 12.8777 131.329 13.4179 131.562 14.0515C131.796 14.6855 131.912 15.3971 131.912 16.186C131.912 17.3631 131.657 18.3493 131.144 19.1449C130.631 19.9404 129.915 20.5128 128.993 20.8618L133.256 25.1499H129.752Z"
        fill={color}
      />
    </svg>
  );
};
