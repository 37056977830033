import * as Headless from "@headlessui/react";
import React, { FC, PropsWithChildren, useState } from "react";
import { CloseMenuIcon, OpenMenuIcon } from "../icons";
import { NavbarItem } from "../navbar/navbar";

const MobileSidebar = ({
  open,
  close,
  children
}: React.PropsWithChildren<{ open: boolean; close: () => void }>) => {
  return (
    <Headless.Dialog open={open} onClose={close} className="lg:hidden">
      <Headless.DialogBackdrop
        transition
        className="fixed inset-0 bg-black/30 transition data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <Headless.DialogPanel
        transition
        className="fixed safe-area-inset-bottom safe-area-inset-top w-full max-w-80 p-2 safe-area-inset-y-paddingx transition duration-300 ease-in-out data-[closed]:-translate-x-full"
      >
        <div className="flex h-full flex-col rounded-lg bg-white shadow-sm safe-area-inset-y-padding ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10">
          <div className="-mb-3 px-4 pt-3">
            <Headless.CloseButton as={NavbarItem} aria-label="Close navigation">
              <CloseMenuIcon />
            </Headless.CloseButton>
          </div>
          {children}
        </div>
      </Headless.DialogPanel>
    </Headless.Dialog>
  );
};

const MobileHeader: FC<PropsWithChildren<{ onClick: () => void }>> = ({
  onClick,
  children
}) => {
  return (
    <header className="fixed w-full lg:hidden safe-area-inset-top-padding safe-area-inset-y-padding bg-gray-100 dark:bg-zinc-950 z-[101] flex items-center px-4">
      <div className="flex flex-1 px-2">
        <div className="py-2.5 px-1">
          <NavbarItem onClick={onClick} aria-label="Open navigation">
            <OpenMenuIcon />
          </NavbarItem>
        </div>
        <div className="min-w-0 flex-1">{children}</div>
      </div>
    </header>
  );
};

const MobileFooter: FC<PropsWithChildren> = ({ children }) => {
  return (
    <footer className="fixed bottom-0 flex-shrink-0 safe-area-inset-bottom-padding safe-area-inset-y-padding w-full lg:hidden bg-gray-100 dark:bg-zinc-950">
      {children ? (
        <div className="max-w-6xl mx-auto p-4 lg:p-6">{children}</div>
      ) : null}
    </footer>
  );
};

export const SidebarLayout = ({
  navbar,
  sidebar,
  footer,
  children
}: React.PropsWithChildren<{
  navbar: React.ReactNode;
  sidebar: React.ReactNode;
  footer?: React.ReactNode;
}>) => {
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="relative isolate flex min-h-svh max-h-svh w-full bg-white max-lg:flex-col lg:bg-zinc-100 dark:bg-zinc-900 dark:lg:bg-zinc-950">
      {/* Sidebar on desktop */}
      <div className="fixed inset-y-0 left-0 w-64 max-lg:hidden">{sidebar}</div>

      {/* Sidebar on mobile */}
      <MobileSidebar open={showSidebar} close={() => setShowSidebar(false)}>
        {sidebar}
      </MobileSidebar>

      {/* Navbar on mobile */}
      <MobileHeader onClick={() => setShowSidebar(true)}>{navbar}</MobileHeader>

      {/* Content */}
      <main className="flex flex-1 flex-col overflow-y-auto compensate-header compensate-footer lg:pb-2 safe-area-inset-y-padding lg:min-w-0 lg:pl-64 lg:pr-2 lg:pt-2">
        <div className="grow p-6 lg:rounded-lg lg:bg-white lg:p-10 lg:shadow-sm lg:ring-1 lg:ring-zinc-950/5 dark:lg:bg-zinc-900 dark:lg:ring-white/10">
          <div className="mx-auto max-w-screen-2xl">{children}</div>
        </div>
      </main>

      {/* Footer on mobiles */}
      <MobileFooter>{footer}</MobileFooter>
    </div>
  );
};
