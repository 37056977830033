import { PerformanceMonthFragment } from "@/app/performance/PerformanceMonth";
import { ErrorBoundary } from "@/lib/common/ErrorBoundary";
import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { copyToClipboard } from "@/lib/utils/copyToClipboard";
import { ErrorBox } from "@/ui/error/ErrorBox";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { Heading } from "@/ui/typo/heading";
import { Text } from "@/ui/typo/text";
import { FC } from "react";
import { PerformanceChartNivo } from "./PerformanceChartNivo";
import { PerformanceCopyButton } from "./PerformanceCopyButton";
import {
  PerformanceMonth,
  PerformanceMonthSkeleteon
} from "./PerformanceMonth";
import { PerformanceTitle } from "./PerformanceValue";

export const PlantPerformanceFragment = graphql(
  `
    fragment PlantPerformance on Plant {
      id
      name
      performances {
        ...PerformanceMonth
      }
    }
  `,
  [PerformanceMonthFragment]
);

export type PlantPerformanceProps = {
  data: FragmentOf<typeof PlantPerformanceFragment> | undefined;
  year: number;
  quarter: number;
  loading?: boolean;
};

export const PlantPerformance: FC<PlantPerformanceProps> = ({
  data,
  year,
  quarter,
  loading = false
}) => {
  const { t } = useTranslation();
  const plant = readFragment(PlantPerformanceFragment, data);

  const months = plant?.performances
    .filter((performance) => performance.year === year)
    .sort((a, b) => a.month - b.month);

  const monthsByQuarter =
    months?.reduce(
      (acc, month, index) => {
        const quarterIndex = Math.floor(index / 3);
        if (!acc[quarterIndex]) {
          acc[quarterIndex] = [];
        }
        acc[quarterIndex].push(month);
        return acc;
      },
      [] as Array<typeof months>
    ) || [];

  if (loading) {
    return (
      <div className="flex mt-14 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  if (months?.length === 0) {
    return (
      <div className="mt-8">
        <Text>
          {t("performance.noDataFor")}{" "}
          <strong className="text-white">{plant?.name}</strong>
        </Text>
      </div>
    );
  }

  const selectedQuarter = monthsByQuarter[quarter - 1] ?? [];
  const filteredMonths = selectedQuarter.filter((month) => month);

  const handleCopy =
    (field: keyof FragmentOf<typeof PerformanceMonthFragment>) => () => {
      const serialized = serializeValues(selectedQuarter, field);
      copyToClipboard(serialized);
    };

  // Function to serialize extracted values into a string separated by newlines
  const serializeValues = (
    array: FragmentOf<typeof PerformanceMonthFragment>[],
    property: keyof FragmentOf<typeof PerformanceMonthFragment>
  ) => {
    const values = array.map((item) => item[property]);
    return values.join("\n");
  };

  return (
    <div>
      <div className="mt-4 gap-8 hidden xl:grid xl:grid-cols-4">
        <PerformanceTitle>
          {t("performance.plant.fields.month")}
        </PerformanceTitle>
        <PerformanceTitle>
          <div className="flex justify-between items-center">
            <span>{t("performance.plant.fields.yield")}</span>
            <PerformanceCopyButton onClick={handleCopy("pYield")} />
          </div>
        </PerformanceTitle>
        <PerformanceTitle>
          <div className="flex justify-between items-center">
            <span>{t("performance.plant.fields.consumption")}</span>
            <PerformanceCopyButton onClick={handleCopy("pConsumption")} />
          </div>
        </PerformanceTitle>
        <PerformanceTitle>
          <div className="flex justify-between items-center">
            <span>{t("performance.plant.fields.ownConsumption")}</span>
            <PerformanceCopyButton onClick={handleCopy("pOwnConsumption")} />
          </div>
        </PerformanceTitle>
      </div>
      {loading ? (
        <PerformanceMonthSkeleteon />
      ) : (
        selectedQuarter.map((month) => (
          <PerformanceMonth key={month.month} data={month} loading={loading} />
        ))
      )}

      <Heading className="mt-14">
        {t("performance.performanceOf")}
        <strong className="dark:text-zinc-500"> {plant?.name}</strong>{" "}
        {t("performance.plantFor")} {year}
      </Heading>
      <div className="mt-8 border border-gray-800" style={{ height: 500 }}>
        <ErrorBoundary
          fallback={<ErrorBox description={t("error.generic.description")} />}
        >
          {loading ? (
            <div className="w-full h-[100px] dark:bg-white/5 animate-pulse rounded-lg"></div>
          ) : (
            <PerformanceChartNivo months={filteredMonths} />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
};
