import React, { PropsWithChildren } from "react";

type ErrorBoxProps = {
  title?: string;
  description?: string;
};

export const ErrorBox: React.FC<PropsWithChildren<ErrorBoxProps>> = ({
  title,
  description,
  children
}) => {
  return (
    <div
      role="alert"
      aria-live="assertive"
      className="border border-red-500 bg-red-100 text-red-700 p-3 rounded-lg max-w-md my-4 dark:bg-red-900 dark:border-red-700 dark:text-red-300"
    >
      {children ? (
        children
      ) : (
        <>
          {title ? (
            <strong
              className={`block text-lg font-semibold ${description ? "mb-2" : ""}`}
            >
              {title}
            </strong>
          ) : null}

          {description ? (
            <p className="m-0 text-sm leading-relaxed">{description}</p>
          ) : null}
        </>
      )}
    </div>
  );
};
