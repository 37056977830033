import { logger } from "@/lib/logger";
import { PushNotifications } from "@capacitor/push-notifications";
import { useCallback, useState } from "react";

export type UseNativePushNotifications = {
  fcmToken: string | null;
  permissionGranted: boolean;
  init: () => Promise<void>;
  destroy: () => Promise<void>;
};

export const useNativePushNotifications = (): UseNativePushNotifications => {
  const [fcmToken, setFcmToken] = useState<string | null>(null);
  const [permissionGranted, setPermissionGranted] = useState(false);

  const init = useCallback(async () => {
    try {
      let status = await checkPermissions();

      if (status.receive === "prompt") {
        status = await requestPermissions();
      }

      if (status.receive === "granted") {
        await addListeners();

        await register();

        setPermissionGranted(true);
      }

      if (status.receive === "denied") {
        // User denied push notifications. At this point, we don't act upon it yet.
      }
    } catch (error) {
      // TODO Probably Sentry here
      throw new Error("Request for push notifications permissions failed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermissions = () => {
    return PushNotifications.checkPermissions();
  };

  const requestPermissions = () => {
    return PushNotifications.requestPermissions();
  };

  const register = async () => {
    await PushNotifications.register();
  };

  const addListeners = useCallback(async () => {
    await PushNotifications.addListener("registration", (token) => {
      // eslint-disable-next-line no-console
      console.log("FCM Token: ", token.value);
      setFcmToken(token.value);
      // TODO store token somewhere
    });

    await PushNotifications.addListener("registrationError", (err) => {
      logger.error("Registration error: ", err.error);
      // TODO: Sentry probably
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        // eslint-disable-next-line no-console
        console.log("Push notification received: ", notification);
        // TODO: Update the UI to show the notification, increase notifications counter
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification) => {
        // eslint-disable-next-line no-console
        console.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
        // TODO: Tap on the notification should result in navigation to the appropriate screen
      }
    );
  }, []);

  const removeAllListeners = async () => {
    await PushNotifications.removeAllListeners();
  };

  return {
    init,
    destroy: removeAllListeners,
    fcmToken,
    permissionGranted
  };
};
