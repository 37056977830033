import {
  type Auth,
  type AuthError,
  type UserCredential,
  signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword
} from "firebase/auth";
import { useState } from "react";
import { EmailAndPasswordActionHook } from "./types";

export const useSignInWithEmailAndPassword = (
  auth: Auth
): EmailAndPasswordActionHook => {
  const [result, setResult] = useState<UserCredential>();
  const [error, setError] = useState<AuthError>();
  const [loading, setLoading] = useState<boolean>(false);

  const signInWithEmailAndPassword = async (
    email: string,
    password: string
  ) => {
    setLoading(true);
    setError(undefined);

    try {
      const credential = await firebaseSignInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setResult(credential);

      return credential;
    } catch (err) {
      setError(err as AuthError);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return [signInWithEmailAndPassword, result, loading, error];
};
