import { logger } from "@/lib/logger";
import { Capacitor } from "@capacitor/core";

const KEY_PREFIX = "solarify:";

export async function setObject(key: string, value: NonNullable<unknown>) {
  try {
    if (Capacitor.isNativePlatform()) {
      const { Preferences } = await import("@capacitor/preferences");
      await Preferences.set({
        key: KEY_PREFIX + key,
        value: JSON.stringify(value)
      });
    } else {
      localStorage.setItem(KEY_PREFIX + key, JSON.stringify(value));
    }
  } catch (e) {
    logger.error("Error stringifying JSON of Preferences", e);
  }
}

export async function getObject(key: string) {
  try {
    if (Capacitor.isNativePlatform()) {
      const { Preferences } = await import("@capacitor/preferences");
      const pref = await Preferences.get({ key: KEY_PREFIX + key });
      return pref.value ? JSON.parse(pref.value) : null;
    } else {
      const value = localStorage.getItem(KEY_PREFIX + key);
      return value ? JSON.parse(value) : null;
    }
  } catch (e) {
    logger.error("Error parsing JSON of Preferences", e);
    return null;
  }
}
