import { FC } from "react";
import { useTranslation } from "@/lib/i18n";
import { UserRolesRadioField } from "@/app/user-management/UserRolesRadioField";
import { Button } from "@/ui/button/button";
import { ErrorBox } from "@/ui/error/ErrorBox";
import { UseFormReturn } from "react-hook-form";
import { InputField } from "@/ui/form/InputField";

export type UserFormValues = {
  email: string;
  displayName: string;
  password: string;
  phoneNumber?: string;
  roles: string[];
};

type Props = {
  form: UseFormReturn<UserFormValues>;
  onSubmit: (values: UserFormValues) => void;
  loading: boolean;
  isEditMode?: boolean;
  onClose: VoidFunction;
};

export const UserForm: FC<Props> = ({
  onClose,
  onSubmit,
  isEditMode,
  loading,
  form
}) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <InputField
        label={t("userManagement.editForm.fields.email.label")}
        id="email"
        type="email"
        name="email"
        control={control}
      />

      <InputField
        label={t("userManagement.editForm.fields.displayName.label")}
        id="displayName"
        name="displayName"
        control={control}
      />

      {!isEditMode && (
        <InputField
          label={t("userManagement.editForm.fields.password.label")}
          id="password"
          type="password"
          name="password"
          control={control}
          autoComplete="new-password"
        />
      )}

      <InputField
        label={t("userManagement.editForm.fields.phoneNumber.label")}
        id="phoneNumber"
        type="tel"
        name="phoneNumber"
        control={control}
      />

      <UserRolesRadioField name="roles" control={control} />

      {errors.root?.message && <ErrorBox description={errors.root?.message} />}

      <div className="flex justify-end gap-2">
        <Button type="button" outline onClick={onClose}>
          {t("userManagement.editForm.actions.cancel.label")}
        </Button>
        <Button type="submit" loading={loading}>
          {t(
            `userManagement.editForm.actions.${isEditMode ? "edit" : "add"}.label`
          )}
        </Button>
      </div>
    </form>
  );
};
