export type ToastProps = {
  icon?: React.ReactNode;
  title?: string;
  description?: string;
};

export const Toast = ({ title, description, icon }: ToastProps) => {
  return (
    <div className="flex items-center">
      {icon}
      <div className="flex flex-col justify-center gap-1">
        {title ? <p className="font-bold">{title}</p> : null}
        {description ? (
          <p className="text-gray-600 dark:text-gray-400">{description}</p>
        ) : null}
      </div>
    </div>
  );
};
