import { UserAvatar } from "@/app/users/UserAvatar";
import { UserDropdownMenu } from "@/app/users/UserDropdownMenu";
import { useSession } from "@/lib/auth";
import { Dropdown, DropdownButton } from "@/ui/form/dropdown";
import { SidebarItem } from "@/ui/sidebar";
import { ChevronUpIcon } from "@heroicons/react/16/solid";

export const UserAvatarWithInfo = () => {
  const { me } = useSession();

  return (
    <Dropdown>
      <DropdownButton as={SidebarItem}>
        <span className="flex min-w-0 items-center gap-3">
          <UserAvatar className="size-10" />
          {me && (
            <span className="min-w-0">
              <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                {me.displayName}
              </span>
              <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                {me.email}
              </span>
            </span>
          )}
        </span>
        {me && <ChevronUpIcon />}
      </DropdownButton>
      <UserDropdownMenu anchor="top start" />
    </Dropdown>
  );
};
