import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

export type PerformanceCopyButtonProps = {
  onClick: () => void;
};

export const PerformanceCopyButton = ({
  onClick
}: PerformanceCopyButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="text-3xl/8 font-semibold sm:text-2xl/8 text-zinc-500 hover:text-zinc-200 w-[20px]"
    >
      <ClipboardDocumentIcon />
    </button>
  );
};
