import { useSession } from "@/lib/auth";
import { mapFirebaseErrorKey } from "@/lib/auth/firebaseErrorTranslator";
import { useTranslation } from "@/lib/i18n";
import {
  UserCredential,
  type AuthError,
  type MultiFactorError
} from "firebase/auth";
import { useAtom } from "jotai";
import { useState, type PropsWithChildren } from "react";
import { EmailAndPasswordForm } from "./EmailAndPasswordForm";
import { ThirdPartyLoginButtons } from "./ThirdPartyLoginButtons";
import { disabledAtom } from "./atoms/loginAtoms";
import {
  mfaErrorAtom,
  secondFactorFlowAtom,
  secondFactorStepAtom
} from "./atoms/mfaAtoms";
import { Divider } from "@/ui/divider";

export const LoginForm = () => {
  const { t } = useTranslation();
  const { isUserEnrolled } = useSession();
  const [error, setError] = useState<AuthError | null>(null);
  const [disabled] = useAtom(disabledAtom);
  const [, setMultiFactorError] = useAtom(mfaErrorAtom);
  const [, setFlow] = useAtom(secondFactorFlowAtom);
  const [, setStep] = useAtom(secondFactorStepAtom);

  const handleLoginSuccess = (result?: UserCredential) => {
    if (result?.user) {
      if (import.meta.env.VITE_MFA_DISABLED === "true") {
        return;
      }
      if (!isUserEnrolled(result.user)) {
        setFlow("enroll");
        setStep("select_second_factor");
      }
    }
  };

  /**
   * Check if the error is due to MFA required.
   *
   * @capacitor-firebase/authentication throws different errors on different platforms.
   * On web, it throws an Firebase Error object with a message that includes code "auth/multi-factor-auth-required".
   * On native, it throws an object with a message property that says "Please complete a second factor challenge to finish signing into this account.".
   *
   * If we skip authentication on native layer, we will receive Firebase Error here, which is needed for further MFA handling (namely in getMultiFactorResolver).
   */
  const handleLoginError = async (error: AuthError) => {
    if (
      error instanceof Error &&
      "code" in error &&
      error.code === "auth/multi-factor-auth-required"
    ) {
      setMultiFactorError(error as MultiFactorError);
      setFlow("second_factor");
      setStep("send_code");
    } else {
      handleError(error);
    }
  };

  const handleError = (err: unknown) => {
    const errorKey = mapFirebaseErrorKey(err as AuthError);
    const errorMessage = t(`errors.auth.${errorKey}`, {
      ns: "common"
    } as unknown as string);

    setError({
      code: (err as AuthError).code || "unknown",
      message: errorMessage
    } as AuthError);
  };

  return (
    <>
      {error && <ErrorBox>{error.message}</ErrorBox>}
      <>
        <EmailAndPasswordForm
          disabled={disabled}
          onSuccess={handleLoginSuccess}
          onError={handleLoginError}
        />
        <Divider className="my-8" />
        <ThirdPartyLoginButtons
          disabled={disabled}
          onSuccess={handleLoginSuccess}
          onError={(err) => {
            handleLoginError(err as AuthError);
          }}
        />
      </>
    </>
  );
};

const ErrorBox = ({ children }: PropsWithChildren) => {
  return (
    <div className="mt-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
      {children}
    </div>
  );
};
