import { FC, PropsWithChildren } from "react";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/divider";
import { Badge } from "@/ui/badge";

export type PerformanceTitleProps = {
  title: string;
  value: number | string | null;
  estimation?: number | null;
  loading?: boolean;
};

export const PerformanceTitle: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="mt-6 text-lg font-medium text-gray-900 dark:text-gray-100 sm:text-sm">
      {children}
    </div>
  );
};

export const PerformanceValue: FC<PerformanceTitleProps> = ({
  title,
  value,
  loading = false,
  ...props
}) => {
  const { t } = useTranslation();
  let estimation = props.estimation;

  if (value && estimation) {
    // Convert value to number if it's a string
    const numericValue = typeof value === "string" ? parseFloat(value) : value;

    // Generate a random percentage between -15% and 15%
    const randomPercentage = (Math.random() * 30 - 15) / 100;

    // Calculate the adjustment
    const adjustment = numericValue * randomPercentage;

    // Apply the adjustment to the value
    const adjustedValue = numericValue + adjustment;

    // Round to 2 decimal places
    estimation = Number(adjustedValue.toFixed(2));
  }

  const calculateChange = () => {
    if (!estimation) return;
    const change =
      ((parseInt(value?.toString() ?? "") - estimation) / estimation) * 100;
    return change.toFixed(2);
  };

  const change = calculateChange();

  if (loading) {
    return (
      <div>
        <Divider />
        <div className="w-full h-[100px] mt-3 dark:bg-white/5 animate-pulse rounded-lg"></div>
      </div>
    );
  }

  return (
    <div>
      <Divider />
      <PerformanceTitle>
        <div className="xl:hidden">
          {props.estimation ? (
            <>
              {title}
              <span className="text-zinc-500">
                {t("performance.month.value.estimation")}
              </span>
            </>
          ) : (
            title
          )}
        </div>
      </PerformanceTitle>
      <div className="mt-3 text-3xl/8 font-semibold sm:text-2xl/8 text-zinc-500">
        <span className="text-zinc-500">{value}</span>
        {props.estimation && (
          <span className="text-zinc-700"> {estimation}</span>
        )}
      </div>
      {estimation && (
        <div className="mt-3 text-sm/6 sm:text-xs/6">
          <Badge color={change?.toString().startsWith("-") ? "pink" : "lime"}>
            {change}%
          </Badge>
          <span className="text-zinc-500">
            {t("performance.month.value.difference")}
          </span>
        </div>
      )}
    </div>
  );
};
