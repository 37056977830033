import { useSession } from "@/lib/auth/useSession";
import { useLazyQuery } from "@apollo/client";
import { PropsWithChildren, useEffect } from "react";
import { MeQuery } from "./Me.query";
import { defineAbilitiesForMe } from "./abilities";

export function MeProvider({ children }: PropsWithChildren) {
  const [getMe, { data, called }] = useLazyQuery(MeQuery);
  const { setMe, isSignedIn, setAbility } = useSession();

  useEffect(() => {
    const fetchUser = async () => {
      if (isSignedIn() && !called) {
        await getMe();
      }
    };
    fetchUser();
  }, [isSignedIn, called, getMe]);

  useEffect(() => {
    if (data?.me) {
      const { me } = data;
      setMe(me);
      const ability = defineAbilitiesForMe(me);
      setAbility(ability);
    }
  }, [data, setMe, setAbility]);

  return children;
}
