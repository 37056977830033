import { FragmentOf, graphql, readFragment } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { logger } from "@/lib/logger";
import { Dialog } from "@/ui/dialog";
import { toaster } from "@/ui/toast/Toaster";
import { useMutation } from "@apollo/client";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { UserForm, UserFormValues } from "./UserForm";

// TODO: resolve how to solve this, custom fragment? circular deps?
export const UpdateUserDialogFragment = graphql(`
  fragment UpdateUserDialog on User {
    id
    uid
    email
    displayName
    phoneNumber
    roles {
      id
      name
    }
  }
`);

const UpdateUserMutation = graphql(
  `
    mutation UpdateUser($input: UpdateUserInput!) {
      updateUser(updateUserInput: $input) {
        ...UpdateUserDialog
      }
    }
  `,
  [UpdateUserDialogFragment]
);

type Props = {
  data: FragmentOf<typeof UpdateUserDialogFragment>;
  isOpen: boolean;
  onClose: () => void;
};

export const UpdateUserDialog: FC<Props> = ({ data, onClose, isOpen }) => {
  const { t } = useTranslation();

  const user = readFragment(UpdateUserDialogFragment, data);

  const [updateUser, { loading }] = useMutation(UpdateUserMutation);
  const form = useForm<UserFormValues>({
    defaultValues: {
      email: user.email ?? "",
      displayName: user.displayName ?? "",
      phoneNumber: user.phoneNumber || "",
      roles: user.roles.map((role) => role.id.toString())
    }
  });

  const handleSubmit = async (formData: UserFormValues) => {
    try {
      await updateUser({
        variables: {
          input: {
            userId: user.uid ?? "", // TODO: should be id right?
            email: formData.email ?? "",
            displayName: formData.displayName ?? "",
            phoneNumber: formData.phoneNumber || null,
            roleIds: formData.roles.map((r) => parseInt(r, 10))
          }
        },
        onCompleted: () => {
          onClose();
        },
        onError: (error) => {
          form.setError("root", {
            type: "api",
            message: t("userManagement.editForm.actions.edit.error.description")
          });
          toaster.error({
            description: t(
              "userManagement.editForm.actions.edit.error.description"
            )
          });
          logger.error(error);
        }
      });
    } catch (error) {
      logger.error("Error updating user:", error);
    }
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        title={t("userManagement.editForm.actions.edit.label")}
      >
        <UserForm
          form={form}
          loading={loading}
          onClose={onClose}
          onSubmit={handleSubmit}
          isEditMode
        />
      </Dialog>
    </>
  );
};
