import { Capacitor } from "@capacitor/core";
import { FirebaseApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth
} from "firebase/auth";

function whichAuth(app: FirebaseApp) {
  let auth;
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(app, {
      persistence: indexedDBLocalPersistence
    });
  } else {
    auth = getAuth();
  }
  return auth;
}

export function initAuth(firebaseApp: FirebaseApp) {
  // Initialize Firebase Authentication
  const auth = whichAuth(firebaseApp);

  // Configure Google Auth Provider
  const provider = new GoogleAuthProvider();

  // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");

  return { auth, provider };
}
