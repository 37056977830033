import { initFirebase } from "../firebase/initFirebase";
import { initAuth } from "./initAuth";
import { getSessionProvider } from "./SessionProvider";
import { useSignInWithEmailAndPassword as useSignInWithEmailAndPasswordHook } from "./useSignInWithEmailAndPassword";
import { useSignInWithGoogle as useSignInWithGoogleHook } from "./useSignInWIthGoogle";
import { useSignOut as signOutHook } from "./useSignOut";

const firebaseApp = initFirebase();

const { auth, provider } = initAuth(firebaseApp);

export function useSignInWithGoogle() {
  return useSignInWithGoogleHook(auth);
}

export function useSignInWithEmailAndPassword() {
  return useSignInWithEmailAndPasswordHook(auth);
}

export function useSignOut() {
  return signOutHook(auth);
}

const SessionProvider = getSessionProvider(auth);

export { auth, firebaseApp, provider, SessionProvider };

export {
  sendPasswordResetEmail,
  type AuthError,
  type User
} from "firebase/auth";
export { MeQuery } from "./Me.query";
export { MeProvider } from "./MeProvider";
export { useSession } from "./useSession";
