import { FragmentOf, graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { Divider } from "@/ui/divider";
import { FC } from "react";
import { PerformanceValue } from "./PerformanceValue";

export const PerformanceMonthFragment = graphql(`
  fragment PerformanceMonth on Performance @_unmask {
    year
    month
    pYield
    pConsumption
    pOwnConsumption
  }
`);

export type PerformanceMonthProps = {
  data: FragmentOf<typeof PerformanceMonthFragment>;
  loading?: boolean;
};

export const PerformanceMonth: FC<PerformanceMonthProps> = ({
  data,
  loading = false
}) => {
  const { t, i18n } = useTranslation();
  const { year, month, pYield, pConsumption, pOwnConsumption } = data;

  const getMonthName = (month: number) => {
    return new Date(year, month - 1, 1).toLocaleString(i18n.language, {
      month: "long"
    });
  };

  return (
    <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
      <PerformanceValue
        title={t("performance.month.fields.month")}
        value={getMonthName(month)}
        loading={loading}
      />
      <PerformanceValue title="Yield" value={pYield} loading={loading} />
      <PerformanceValue
        title={t("performance.month.fields.consumption")}
        value={pConsumption}
        loading={loading}
      />
      <PerformanceValue
        title={t("performance.month.fields.ownConsumption")}
        value={pOwnConsumption}
        loading={loading}
      />
    </div>
  );
};

export const PerformanceMonthSkeleteon: FC = () => (
  <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
    <div>
      <Divider />
      <div className="w-full h-[100px] mt-3 dark:bg-white/5 animate-pulse rounded-lg"></div>
    </div>
    <div>
      <Divider />
      <div className="w-full h-[100px] mt-3 dark:bg-white/5 animate-pulse rounded-lg"></div>
    </div>
    <div>
      <Divider />
      <div className="w-full h-[100px] mt-3 dark:bg-white/5 animate-pulse rounded-lg"></div>
    </div>
    <div>
      <Divider />
      <div className="w-full h-[100px] mt-3 dark:bg-white/5 animate-pulse rounded-lg"></div>
    </div>
  </div>
);
