import { useSession, useSignOut } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import { Avatar, AvatarProps } from "@/ui/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu
} from "@/ui/form/dropdown";
import { SidebarItem } from "@/ui/sidebar";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  LanguageIcon,
  MoonIcon,
  SunIcon
} from "@heroicons/react/16/solid";
import { useState } from "react";
import { LanguageDialog } from "./LanguageDialog";
import { useTheme } from "@/ui/Theme";

type UserDropdownMenuProps = {
  anchor: "top start" | "top end" | "bottom start" | "bottom end";
};

export const UserDropdownMenu = ({ anchor }: UserDropdownMenuProps) => {
  const [signOut] = useSignOut();
  const { theme, toggleTheme } = useTheme();
  const [isLanguageDialogOpen, setIsLanguageDialogOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <LanguageDialog
        open={isLanguageDialogOpen}
        onClose={() => setIsLanguageDialogOpen(false)}
      />
      <DropdownMenu className="min-w-64" anchor={anchor}>
        {/* <DropdownItem href="#">
          <UserCircleIcon />
          <DropdownLabel>{t("menu.account")}</DropdownLabel>
        </DropdownItem>
        <DropdownDivider /> */}
        {/* <DropdownItem href={import.meta.env.VITE_SUPPORT_EMAIL}>
          <LightBulbIcon />
          <DropdownLabel>{t("menu.feedback")}</DropdownLabel>
        </DropdownItem>
        <DropdownDivider /> */}
        <DropdownItem onClick={() => setIsLanguageDialogOpen(true)}>
          <LanguageIcon />
          <DropdownLabel>{t("menu.language")}</DropdownLabel>
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem onClick={() => toggleTheme()}>
          {theme === "dark" ? (
            <>
              <SunIcon />
              <DropdownLabel>{t("menu.theme.light")}</DropdownLabel>
            </>
          ) : (
            <>
              <MoonIcon />
              <DropdownLabel>{t("menu.theme.dark")}</DropdownLabel>
            </>
          )}
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem href="#">
          <ArrowRightStartOnRectangleIcon />
          <DropdownLabel onClick={signOut}>{t("menu.signOut")}</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </>
  );
};

export const UserAvatar = (props: AvatarProps) => {
  const { me } = useSession();
  const { square = true, alt = "avatar", ...rest } = props;

  const getInitials = () => {
    if (me?.photoUrl) {
      return undefined;
    }

    if (me?.displayName) {
      return me.displayName
        .split(" ")
        .map((name) => name[0])
        .join("");
    } else {
      return me?.email?.charAt(0);
    }
  };

  if (me) {
    return (
      <Avatar
        src={me.photoUrl}
        initials={getInitials()}
        square={square}
        alt={alt}
        {...rest}
      />
    );
  }

  return <Avatar initials="..." square={square} alt={alt} {...rest} />;
};

export const UserAvatarWithInfo = () => {
  const { me, isSignedIn } = useSession();

  return (
    <Dropdown>
      <DropdownButton as={SidebarItem}>
        <span className="flex min-w-0 items-center gap-3">
          <UserAvatar className="size-10" />
          {me && (
            <span className="min-w-0">
              <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                {me.displayName}
              </span>
              <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                {me.email}
              </span>
            </span>
          )}
        </span>
        {isSignedIn() && <ChevronUpIcon />}
      </DropdownButton>
      <UserDropdownMenu anchor="top start" />
    </Dropdown>
  );
};
