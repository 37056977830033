import { secondFactorFlowAtom } from "@/app/users/atoms/mfaAtoms";
import { LoginPage } from "@/app/users/LoginPage";
import { EnrollSecondFactorPage } from "@/app/users/multi-factor-authentication/EnrollSecondFactorPage";
import { VerifySecondFactorPage } from "@/app/users/multi-factor-authentication/VerifySecondFactorPage";
import { UserAvatar } from "@/app/users/UserAvatar";
import { UserAvatarWithInfo } from "@/app/users/UserAvatarWithInfo";
import { UserDropdownMenu } from "@/app/users/UserDropdownMenu";
import { useSession } from "@/lib/auth";
import { useTranslation } from "@/lib/i18n";
import {
  HomeIcon,
  QuestionMarkCircleIcon,
  Square2StackIcon,
  UserIcon,
  ShieldCheckIcon
} from "@heroicons/react/20/solid";
import { useAtom } from "jotai";
import { Outlet, useLocation } from "react-router-dom";
import { Toaster } from "@/ui/toast/Toaster";
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer
} from "@/ui/navbar/navbar";
import { Dropdown, DropdownButton } from "@/ui/form/dropdown";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer
} from "@/ui/sidebar";
import { Logo } from "@/ui/Logo";
import { SidebarLayout } from "@/ui/layouts/sidebar-layout";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { DocumentsIcon } from "@/ui/icons/DocumentsIcon";

export const AppLayout: React.FC = () => {
  const { t } = useTranslation();
  const session = useSession();
  const location = useLocation();
  const { pathname } = location;

  const [flow] = useAtom(secondFactorFlowAtom);

  if (session.loading) {
    return (
      <div className="flex mt-14 items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  // User is not signed in
  if (!session.isSignedIn()) {
    return flow === "second_factor" ? (
      <VerifySecondFactorPage />
    ) : (
      <LoginPage />
    );
  }
  // Skip enrolling if MFA is disabled
  if (import.meta.env.VITE_MFA_DISABLED !== "true") {
    // In case user refreshes the app during enrolling
    if (flow === null && !session.isUserEnrolled(session.firebaseUser)) {
      return <EnrollSecondFactorPage />;
    }

    if (flow === "enroll") {
      return <EnrollSecondFactorPage />;
    }
  }

  const { ability } = session;

  return (
    <div data-testid="authenticated-app-layout">
      <SidebarLayout
        navbar={
          <Navbar>
            <NavbarSpacer />
            <NavbarSection>
              <Dropdown>
                <DropdownButton as={NavbarItem}>
                  <UserAvatar />
                </DropdownButton>
                <UserDropdownMenu anchor="bottom end" />
              </Dropdown>
            </NavbarSection>
          </Navbar>
        }
        sidebar={
          <Sidebar>
            <SidebarHeader>
              <Logo className="px-2" />
            </SidebarHeader>
            <SidebarBody>
              <SidebarSection>
                {ability?.can("read", "dashboard") && (
                  <SidebarItem href="/" current={pathname === "/"}>
                    <HomeIcon />
                    <SidebarLabel>{t("navigation.dashboard")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "plants") && (
                  <SidebarItem
                    href="/plants"
                    current={pathname.startsWith("/plants")}
                  >
                    <Square2StackIcon />
                    <SidebarLabel>{t("navigation.plants")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "resources") && (
                  <SidebarItem
                    href="/permissions"
                    current={pathname.startsWith("/permissions")}
                  >
                    <ShieldCheckIcon />
                    <SidebarLabel>{t("navigation.permissions")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "documents") && (
                  <SidebarItem
                    href="/documents"
                    current={pathname.startsWith("/documents")}
                  >
                    <DocumentsIcon />
                    <SidebarLabel>{t("navigation.documents")}</SidebarLabel>
                  </SidebarItem>
                )}
                {ability?.can("read", "users") && (
                  <SidebarItem
                    href="/users"
                    current={pathname.startsWith("/users")}
                  >
                    <UserIcon />
                    <SidebarLabel>{t("navigation.users")}</SidebarLabel>
                  </SidebarItem>
                )}
              </SidebarSection>
              <SidebarSpacer />
              <SidebarSection>
                <SidebarItem
                  href={`mailto:${import.meta.env.VITE_SUPPORT_EMAIL}`}
                  target="_blank"
                >
                  <QuestionMarkCircleIcon />
                  <SidebarLabel>{t("navigation.support")}</SidebarLabel>
                </SidebarItem>
              </SidebarSection>
            </SidebarBody>
            <SidebarFooter className="max-lg:hidden">
              <UserAvatarWithInfo />
            </SidebarFooter>
          </Sidebar>
        }
      >
        <Outlet />
      </SidebarLayout>

      <Toaster />
    </div>
  );
};
