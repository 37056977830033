import { Capacitor } from "@capacitor/core";
import { createContext, FC, PropsWithChildren, useEffect } from "react";
import { useNativePushNotifications } from "./useNativePushNotifications";

export type PushNotificationContextType = {
  /**
   * The FCM token used by Firebase Messaging SDK.
   */
  fcmToken: string | null;
  /**
   * Flag to indicate if the user has granted permission to receive push notifications.
   */
  permissionGranted: boolean;
};

const PushNotificationContext = createContext<PushNotificationContextType>({
  fcmToken: null,
  permissionGranted: false
});

const PushNotificationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { init, fcmToken, permissionGranted, destroy } =
    useNativePushNotifications();

  /**
   * Initialize the native push notifications when the component mounts.
   */
  useEffect(() => {
    const run = async () => {
      if (Capacitor.isNativePlatform()) {
        await init();
      } else {
        // Web push notifications initialization here
      }
    };

    run();

    return () => {
      if (Capacitor.isNativePlatform()) {
        destroy();
      } else {
        // Web push notifications cleanup here
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PushNotificationContext.Provider value={{ fcmToken, permissionGranted }}>
      {children}
    </PushNotificationContext.Provider>
  );
};

export { PushNotificationContext, PushNotificationProvider };
