import { logger } from "@/lib/logger";

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    () => {
      // TODO show snackbar to indicate success
      // eslint-disable-next-line no-console
      console.log("Copying to clipboard was successful!");
    },
    (err) => {
      logger.error("Could not copy text: ", err);
    }
  );
}
