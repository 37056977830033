import { graphql } from "@/lib/data/graphql";
import { MeFragment } from "./Me.fragment";

export const MeQuery = graphql(
  `
    query Me {
      me {
        id
        ...MeFragment
      }
    }
  `,
  [MeFragment]
);
