// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/line
import { useTranslation } from "@/lib/i18n";
import { ResponsiveLine } from "@nivo/line";
import { PerformanceMonthProps } from "./PerformanceMonth";
import { useTheme } from "@/ui/Theme";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

type PerformanceChartNivoProps = {
  months?: PerformanceMonthProps["data"][];
};

export const PerformanceChartNivo = ({ months }: PerformanceChartNivoProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const series = [
    {
      label: t("performance.chart.fields.yield"),
      key: "pYield"
    },
    {
      label: t("performance.chart.fields.consumption"),
      key: "pConsumption"
    },
    {
      label: t("performance.chart.fields.ownConsumption"),
      key: "pOwnConsumption"
    }
  ];

  const data = series
    .map((s) => ({
      id: s.label,
      // color: s.color,
      data: (months ?? []).map((m) => ({
        x: m.month,
        y: m[s.key as keyof PerformanceMonthProps["data"]]
      }))
    }))
    .filter((d) => d.data.length > 0);

  const color = theme === "dark" ? "white" : "black";

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 30, right: 30, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "months",
        legendOffset: 36,
        legendPosition: "middle",
        truncateTickAt: 0
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "kw",
        legendOffset: -40,
        legendPosition: "middle",
        truncateTickAt: 0
      }}
      isInteractive={false}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel="data.yFormatted"
      pointLabelYOffset={-12}
      enableTouchCrosshair={true}
      useMesh={true}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: color // Change this color for axis tick labels
            }
          },
          legend: {
            text: {
              fill: color // Change this color for axis labels
            }
          }
        },
        legends: {
          text: {
            fill: color // Change this color for legends
          }
        },
        grid: {
          line: {
            stroke: "#444444", // Change this color for the grid lines
            strokeWidth: 1,
            strokeDasharray: "4 4" // Optional: adds a dashed pattern to the grid lines
          }
        }
      }}
      legends={[
        {
          anchor: "top-left",
          direction: "column",
          justify: false,
          translateX: 20,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)"
        }
      ]}
    />
  );
};
