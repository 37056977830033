import { FC, PropsWithChildren } from "react";
import {
  ErrorBoundary as ThirdPartyErrorBoundary,
  ErrorBoundaryProps as ThirdPartyErrorBoundaryProps
} from "react-error-boundary";

/**
 * Documentation: https://github.com/bvaughn/react-error-boundary
 */

export type ErrorBoundaryProps =
  PropsWithChildren<ThirdPartyErrorBoundaryProps>;

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({
  children,
  ...props
}) => {
  return (
    <ThirdPartyErrorBoundary {...props}>{children}</ThirdPartyErrorBoundary>
  );
};
