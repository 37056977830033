import { PermissionsPageQuery } from "@/app/permissions/RolePermissionsPage";
import { PermissionRoleItemFragment } from "@/app/permissions/RolePermissionsTable";
import { graphql, readFragment } from "@/lib/data/graphql";
import { useMutation } from "@apollo/client";

const UpdateRoleMutation = graphql(`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(updateInput: $input) {
      id
      name
      resources {
        id
        name
      }
    }
  }
`);

export const useUpdateRole = () => {
  const [updateRole, { error, loading, data }] = useMutation(
    UpdateRoleMutation,
    {
      update: (cache, { data }) => {
        if (data?.updateRole) {
          const existingData = cache.readQuery({
            query: PermissionsPageQuery
          });

          // update the cache with new user
          cache.writeQuery({
            query: PermissionsPageQuery,
            data: {
              ...existingData,
              adminRoles: [
                ...(existingData?.adminRoles.filter(
                  (r) =>
                    readFragment(PermissionRoleItemFragment, r).id !==
                    data.updateRole.id
                ) ?? []),
                data.updateRole
              ]
            }
          });
        }
      }
    }
  );

  return { updateRole, error, data, loading };
};
