import { PropsWithChildren } from "react";

export const TableFilterWrapper: React.FC<PropsWithChildren> = ({
  children
}) => {
  return (
    <div className="flex items-center gap-4 flex-wrap sm:overflow-auto">
      {children}
    </div>
  );
};
