import { MultiFactorError, PhoneMultiFactorGenerator } from "firebase/auth";
import { atom } from "jotai";
import {
  SecondFactorFlow,
  SecondFactorStep
} from "../multi-factor-authentication/types";

export const secondFactorFlowAtom = atom<SecondFactorFlow | null>(null);
export const secondFactorStepAtom = atom<SecondFactorStep | null>(null);
export const factorIdAtom = atom(PhoneMultiFactorGenerator.FACTOR_ID);
export const mfaErrorAtom = atom<MultiFactorError | null>(null);
