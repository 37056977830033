import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { logger } from "@/lib/logger";
import { Alert, AlertActions, AlertDescription, AlertTitle } from "@/ui/alert";
import { Button } from "@/ui/button/button";
import { toaster } from "@/ui/toast/Toaster";
import { useMutation } from "@apollo/client";

const ShareDocumentMutation = graphql(`
  mutation ShareDocument($input: SendEmailInput!) {
    sendEmail(input: $input)
  }
`);
type ShareByEmailAlertProps = {
  email: string;
  subject: string;
  text: string;
  isOpen: boolean;
  onClose: VoidFunction;
};

export const DocumentShareDialog: React.FC<ShareByEmailAlertProps> = ({
  email,
  subject,
  text,
  onClose,
  isOpen
}) => {
  const { t } = useTranslation();
  const [triggerSending, { loading }] = useMutation(ShareDocumentMutation);

  const handleSending = async () => {
    triggerSending({
      variables: {
        input: {
          to: email,
          subject,
          text
        }
      },
      onCompleted: () => {
        toaster.success({
          description: t("documents.listing.actions.share.success.description")
        });
        onClose();
      },
      onError: (error) => {
        toaster.error({
          description: t("documents.listing.actions.share.error.description")
        });
        logger.error(error);
      }
    });
  };

  return (
    <>
      <Alert open={isOpen} onClose={onClose}>
        <AlertTitle>
          {t("documents.listing.actions.share.dialog.title")}
        </AlertTitle>
        <AlertDescription>
          {t("documents.listing.actions.share.dialog.description", { email })}
        </AlertDescription>
        <AlertActions>
          <Button plain onClick={() => onClose()}>
            {t("documents.listing.actions.share.dialog.cancel")}
          </Button>
          <Button loading={loading} onClick={handleSending}>
            {t("documents.listing.actions.share.dialog.send")}
          </Button>
        </AlertActions>
      </Alert>
    </>
  );
};
