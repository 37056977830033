import { PermissionsPageQuery } from "@/app/permissions/RolePermissionsPage";
import { graphql } from "@/lib/data/graphql";
import { useTranslation } from "@/lib/i18n";
import { logger } from "@/lib/logger";
import { Button } from "@/ui/button/button";
import { Dialog, DialogActions, DialogBody, DialogTitle } from "@/ui/dialog";
import { InputField } from "@/ui/form/InputField";
import { LoadingSpinner } from "@/ui/loading-spinner";
import { toaster } from "@/ui/toast/Toaster";
import { useMutation } from "@apollo/client";
import { PlusIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";
import { useForm } from "react-hook-form";

const AddRoleMutation = graphql(`
  mutation AddRole($input: CreateRoleInput!) {
    addRole(createRoleInput: $input) {
      id
      name
      resources {
        name
      }
    }
  }
`);

type FormData = {
  name: string;
};

type Props = {
  setSelectedRoleId: (roleId: string) => void;
};

export const AddRoleDialogBtn: React.FC<Props> = ({ setSelectedRoleId }) => {
  const { t } = useTranslation(); // Translation hook

  const [isOpen, setIsOpen] = useState(false);
  const [addRole, { loading }] = useMutation(AddRoleMutation);

  const { control, reset, handleSubmit } = useForm<FormData>({
    defaultValues: { name: "" }
  });

  const handleAddRole = (formData: FormData) => {
    addRole({
      variables: { input: { name: formData.name, resourceNames: [] } },
      onCompleted: (data) => {
        if (data.addRole.id) {
          setSelectedRoleId(data.addRole.id.toString());
        }

        setIsOpen(false);
        reset();
      },
      update: (cache, { data }) => {
        if (!data?.addRole) {
          return;
        }

        const existingData = cache.readQuery({
          query: PermissionsPageQuery
        });

        // update the cache with new user
        cache.writeQuery({
          query: PermissionsPageQuery,
          data: {
            ...existingData,
            adminRoles: [...(existingData?.adminRoles ?? []), data.addRole]
          }
        });
      },
      onError: (error) => {
        toaster.error({
          description: t("permissions.newRole.error.description")
        });
        logger.error(error);
      }
    });
  };

  return (
    <>
      <Button outline className="mt-auto" onClick={() => setIsOpen(true)}>
        {t("permissions.newRole.label")}
        <PlusIcon />
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{t("permissions.newRole.label")}</DialogTitle>

        <form onSubmit={handleSubmit(handleAddRole)}>
          <DialogBody>
            <InputField
              id="name"
              name="name"
              label={t("permissions.newRole.fields.roleName.label")}
              placeholder={t("permissions.newRole.fields.roleName.placeholder")}
              control={control}
            />
          </DialogBody>
          <DialogActions>
            <Button plain onClick={() => setIsOpen(false)}>
              {t("permissions.newRole.actions.cancel")}
            </Button>
            <Button
              disabled={loading}
              className={clsx({ "pointer-events-none opacity-50": loading })}
              type="submit"
            >
              {loading ? (
                <div className="pr-2">
                  <LoadingSpinner size="sm" />
                </div>
              ) : null}
              {t("permissions.newRole.actions.add")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
